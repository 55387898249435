import { Link } from "react-router-dom/cjs/react-router-dom.min";

function EventsTableRow ({event}){
    
    return(
        <tr>
            <td className='text-center'>
                {new Date(event.date).toLocaleDateString()}
                
            </td>
            <td className='text-start'>
                <Link to={`/event?event=${event.id}`}>
                {event.name}
                </Link>
            </td>
            <td>
                {/* if courses.course_name is not null, print the name*/}
                {event.courses ? event.courses.course_name : "" }
            </td>
        </tr>
    )
}

export default EventsTableRow;