import React from 'react'
import Form from 'react-bootstrap/Form'

function EventCreateDisplayFeesRow({selectedDivision,payoutMethods,layouts}){
    

    return(
        <tr>
            <td>
                <Form.Label>{selectedDivision.division_name}</Form.Label>
            </td>
            <td className='text-center'>
                {/* sum of selectedDivision.weekly_payout + selecteddivision.series_payout + selecteddivision.event_costs */}
                ${parseInt(selectedDivision.weekly_payout) + parseInt(selectedDivision.series_payout) + parseInt(selectedDivision.event_costs)}
                
            </td>
            <td className='text-center'>
            {layouts && (layouts.map((layout, index) => (
                    layout.layout_id === selectedDivision.layout_id &&
                        <option key={index} value={layout.layout_id} name={layout.layout_name} id={layout.layout_id}>{layout.layout_name}</option> 
            )))}

            </td>
            <td className='text-center'>
                {selectedDivision.weekly_payout}
            </td>
            <td className='text-center'>
                {/* series payout */}
                {selectedDivision.series_payout}
            </td>
            <td className='text-center'>
                {/* event costs */}
                {selectedDivision.event_costs}
            </td>
            <td className='text-center'>
                {payoutMethods.map((payoutMethod, index) => (
                    payoutMethod.id === selectedDivision.weekly_payout_method &&
                        <option key={index} value={payoutMethod.id} name={payoutMethod.method_name} id={payoutMethod.id}>{payoutMethod.method_name}</option> 
                ))}
            </td>
            <td className='text-center'>
                {payoutMethods.map((payoutMethod, index) => (
                    payoutMethod.id === selectedDivision.series_payout_method &&
                        <option key={index} value={payoutMethod.id} name={payoutMethod.method_name} id={payoutMethod.id}>{payoutMethod.method_name}</option> 
                ))}
            </td>
        </tr>
    )
    
}
export default EventCreateDisplayFeesRow