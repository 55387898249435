import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import FormAlert from "./FormAlert";
import "./DashboardItems.scss";
import { useRouter } from "../util/router";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SectionHeader from "./SectionHeader";
import ScorecardCreateComponent from "./ScorecardCreateComponent";
import { useEntriesByEventPlayerScorecard } from "../util/db";
import ScorecardBeginCheckIn from "./ScorecardBeginCheckIn";
import ScorecardSummaryTablePreview from "./ScorecardSummaryTablePreview";

function ScorecardBeginComponent(props) {
  const router = useRouter();
  const uid = props.userId ? props.userId : null;
  const eventId = props.eventId;
  const auth = props.auth;

  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = useEntriesByEventPlayerScorecard(eventId, uid);

  const itemsAreEmpty = !items || items.length === 0;

  return (
    <>
      {itemsError && (
        <div className="mb-3">
          <FormAlert type="error" message={itemsError.message} />
        </div>
      )}

      {!itemsAreEmpty && (//a scorecard does exist for this player/event
          <>
            <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              size={1}
              spaced={true}
              className="text-center"
          />
            <Row>
              <Col>
                {(itemsStatus === "loading" || itemsAreEmpty) && (//loading or no items
                  <div className="py-5 px-3 align-self-center">
                    {itemsStatus === "loading" && (
                      <Spinner animation="border" variant="primary">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    )}

                    {itemsStatus !== "loading" && itemsAreEmpty && (
                      <>
                      ...
                      </>
                    )}
                  </div>
                )}
                                      
                {itemsStatus !== "loading" && items && items.length > 0 && ( //items exist
                  items.map((item, index) => (
                    <React.Fragment key={index}>
                    {item.group_scorecard_players && item.group_scorecard_players.length > 0
                      ? //player is on a scorecard
                        <>
                        <Card key={index} className="mb-2" border="primary" style={{border:'none'}}>
                        <Card.Header
                          as="h5"
                          className="d-flex justify-content-between align-items-center"
                          style={{background:"#2D083F", text:"white"}}
                        >
                          <Card.Title style={{color:"#ffffff"}}>
                            Scorecard
                          </Card.Title>

                          <div style={{background:"#ffffff",height:"30px",borderRadius:"10%",display:"flex",justifyContent:"center",alignItems:"center"}}>
                            {/* {item.group_scorecard_players.group_scorecard && item.group_scorecard_players[0].group_scorecard.is_complete === false */}
                            {item.group_scorecard_players[0].group_scorecard.is_complete === false
                              ? <span className='mx-2' style={{color:"#2D083F"}}>🥏 Active</span>
                              : <span className='mx-2' style={{color:"#2D083F"}}>✔️ Verified</span>
                            }
                          </div>

                        </Card.Header>
                  
                        <Card.Body className="justify-content-center align-items-center" style={{background:"#FFFFFF"}}>
                              {item.group_scorecard_players[0].group_scorecard.is_complete === false 
                                ? //card is in progress
                                <div className="text-center">

                                  <Row>
                                    <Col>
                                      <ScorecardSummaryTablePreview scorecard={item.group_scorecard_players[0].group_scorecard_id} eventEntryId={item.id} />
                                    </Col>
                                  </Row>
                                  {/* <Row>
                                    <Col>
                                      <p className="mt-2">
                                        Scorecard is in progress.  Click below to resume.
                                      </p>
                                    </Col>
                                  </Row> */}
                                  <Row>
                                    <Col>
                                      <Button
                                        style={{background:'#4D81F4',fontWeight:'bold',border:"0"}}
                                        onClick={() => router.push(`/scorecard?scorecard=${item.group_scorecard_players[0].group_scorecard_id}`)}
                                        className="mt-2"
                                      >
                                        Resume Scorecard
                                      </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                : //card is complete
                                <div className="text-center">
                                  <ScorecardSummaryTablePreview scorecard={item.group_scorecard_players[0].group_scorecard_id} eventEntryId={item.id} />
                                  {/* {console.log(item)} */}
                                    <Button
                                      // center button
                                      style={{background:'#4D81F4',fontWeight:'bold',border:"0"}}
                                      onClick={() => router.push(`/scorecard?scorecard=${item.group_scorecard_players[0].group_scorecard_id}`)}
                                      className="mt-2"
                                    >
                                      View Scorecard
                                    </Button>
                                  </div>
                              }
                              </Card.Body>
                            <Card.Header
                              as="h5"
                              className="d-flex justify-content-between align-items-center"
                              style={{background:"#2D083F", text:"white"}}
                            >

                            </Card.Header>
                          </Card>
                        </>
                      : //player is not on a scorecard
                        <>
                          <Card key={index} className="mb-2" border="primary" style={{border:'none'}}>
                            <Card.Header
                              as="h5"
                              className="d-flex justify-content-between align-items-center"
                              style={{background:"#2D083F", text:"white"}}
                            >
                              <Card.Title style={{color:"#ffffff"}}>
                                Create Scorecard
                              </Card.Title>
                            </Card.Header>
                            
                            <Card.Body className="d-flex justify-content-center align-items-center" style={{background:"#FFFFFF"}}>
                              <ScorecardCreateComponent eventEntryId={item.id} eventId={props.eventId} auth={auth}  />
                            </Card.Body>
                            <Card.Header
                              as="h5"
                              className="d-flex justify-content-between align-items-center"
                              style={{background:"#2D083F", text:"white"}}
                            >

                            </Card.Header>
                          </Card>
                        </>
                    }
                  </React.Fragment>
                  ))
                )}    
              </Col>
            </Row>
        </>
      )}
      {!props.isEventComplete ? (
        <Row>
          <Col>
            <ScorecardBeginCheckIn isCheckedInAlready={props.isCheckedInAlready} isIncompleteCards={props.isIncompleteCards} eventId={props.eventId} canCheckIn={props.canCheckIn}/>
          </Col>
        </Row>  
      ) : (
        <Card className="mb-2" border="primary">
                <Card.Header
                    as="h5"
                    // className="d-flex justify-content-between align-items-center"
                    style={{background:"#2D083F", text:"white"}}
                >
                    <Card.Title style={{color:"#ffffff"}}>
                        Event Complete
                    </Card.Title>
                </Card.Header>
                
                <Card.Body className="justify-content-center align-items-center" style={{background:"#FFFFFF"}}>
                    <p className=" text-center">
                        This event is complete.  Thank you for playing!
                    </p>
                </Card.Body>      
            </Card>
      )
    
    }
      
    </>
  )
}

export default ScorecardBeginComponent;
