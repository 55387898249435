import React from "react";
import Meta from "./../components/Meta";
import { useRouter } from "../util/router";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Section from "../components/Section";
import { Link } from "../util/router";
import { requireAuth } from "./../util/auth";
import { ListGroup } from "react-bootstrap";
import { usePlayers } from "../util/db";
import { useState } from "react";
import { createEventEntry } from "../util/db";
import HrCustom from "../components/HrCustom";
import { useEventDivisions } from "../util/db";
import { createHoleScore, getLayoutHoles } from "../util/db";

function RegisterGuestPage(props) {

  const router = useRouter()
  // const auth = useAuth();
  const [selectedPlayer,setSelectedPlayer] = useState(null)
  const [selectedDivision,setSelectedDivision] = useState(null)
  // const [selectedSideGames,setSelectedSideGames] = useState([])
  // const [totalCharge,setTotalCharge] = useState(0)

    //get divisions
    const {
      data: items,
      status: itemsStatus,
      // error: itemsError,
    } = useEventDivisions(router.query.id);

  //get players
  const {
    data: players,
    status: playersStatus,
    // error: playersError,
  } = usePlayers();

   //signupstate object
   const [signupObject,setSignupObject] = useState({
    event_divisions_id: null, 
    division_name: '', 
    division_cost: 0, 
    side_games: [], 
    side_games_cost: 0, 
    event_id: router.query.id, 
    user_id: selectedPlayer,
    layout_id: null,
  })

  //get divisions
//   const {
//     data: items,
//     status: itemsStatus,
//     // error: itemsError,
//   } = useEventDivisions(router.query.id);

  //get side games
//   const {
//     data: sideGames,
//     status: sideGamesStatus,
//     // error: sideGamesError
//   } = useEventSideGames(router.query.id)

  //totalcharge state
//   const [totalCharge,setTotalCharge] = useState(signupObject.division_cost + signupObject.side_games_cost)

 //set division
 const handleSetDivision = (event_divisions_id,division_cost,division_name,layout_id) => {
  //set division state
  setSelectedDivision(event_divisions_id)

  //set overall state object
    //copy signup object
    const newSignupObject = {...signupObject}

    //copy new division and cost to new object
    newSignupObject.event_divisions_id = event_divisions_id
    newSignupObject.division_cost = division_cost
    newSignupObject.division_name = division_name
    newSignupObject.layout_id = layout_id

    //0verwrite existing signupobject
    setSignupObject(newSignupObject)

    //update total charge state
    // setTotalCharge(signupObject.division_cost + signupObject.side_games_cost)
}



  const handleSetPlayer = (id,name) => {
    //set division state
    setSelectedPlayer(id)

    //set overall state object
      //copy signup object
      const newSignupObject = {...signupObject}

      // //copy new division and cost to new object
      // newSignupObject.event_divisions_id = event_divisions_id
      // newSignupObject.division_cost = division_cost
      // newSignupObject.division_name = division_name
      // newSignupObject.layout_id = layout_id

      //copy player id to new object
      newSignupObject.user_id = id

      //0verwrite existing signupobject
      setSignupObject(newSignupObject)

      //update total charge state
      // setTotalCharge(signupObject.division_cost + signupObject.side_games_cost)
  }

//   //set side game
//   const handleSetSideGame = (new_side_game, side_game_charge) => {

//     //use side game state
//       //copy side games object and add new side game
//       const newSelectedSideGames = [...selectedSideGames, new_side_game]

//       // set state to new object
//       setSelectedSideGames(newSelectedSideGames)

//     //use full object
//       //copy signup object
//       const newSignupObject = {...signupObject}

//       //add side game to array
//       newSignupObject.side_games.push(new_side_game)
//       newSignupObject.side_games_cost += side_game_charge

//       //0verwrite existing signupobject
//       setSignupObject(newSignupObject)

//       //update total charge state
//       setTotalCharge(signupObject.division_cost + signupObject.side_games_cost)

//   }

//   // unset side game
//   const handleUnsetSideGame = (remove_side_game, remove_side_game_cost) => {

//     //use side game state
//       const newSelectedSideGames = [...selectedSideGames]
      
//       if(newSelectedSideGames.includes(remove_side_game)){
//         newSelectedSideGames.splice (newSelectedSideGames.indexOf(remove_side_game), 1)
//       }

//       setSelectedSideGames(newSelectedSideGames)

//     //use full object
//       //copy signup object
//       const newSignupObject = {...signupObject}

//       //remove side game from array
//       const index = newSignupObject.side_games.indexOf(remove_side_game);
//       if (index > -1) {
//         newSignupObject.side_games.splice(index, 1);
//       }

//       //remove cost of side game from charge
//       newSignupObject.side_games_cost -= remove_side_game_cost

//       //0verwrite existing signupobject
//       setSignupObject(newSignupObject)

//       //update total charge state
//       setTotalCharge(signupObject.division_cost + signupObject.side_games_cost)
//   }

//   //return true if this is the selected player
  const isSelectedPlayer = (this_player_id) => {
    if(selectedPlayer === this_player_id){
      return true
    }else{
      return false
    }
  }

  //return true if this is the selected division
  const isSelectedDivision = (this_division_id) => {
    if(selectedDivision === this_division_id){
      return true
    }else{
      return false
    }
  }

//   //return true if this is the selected sidegame
//   const isSelectedSideGame = (this_sidegame_id) => {
//     if(selectedSideGames.includes(this_sidegame_id)){
//       return true
//     }else{
//       return false
//     }
//   }
  
  const handlePlayerConfirmClick = () => {

    if(signupObject.event_divisions_id === null){
      alert('Please select a division')

    }else if(selectedPlayer === null){
      alert('Please select a player')
    }else{

    const registerObject = {       
        event_id: signupObject.event_id,
        user_id: signupObject.user_id,
        event_divisions_id: signupObject.event_divisions_id,
    }


      
    //create event entry
    createEventEntry(registerObject).then((registerData) => {

      //get layout holes for this layout id and wait for response
      getLayoutHoles(signupObject.layout_id).then((layoutData) => {

        //map through layout holes. Perform async fuction to create hole score for each hole. Do not continue until all holes are created
        layoutData.forEach((hole) => {
          //create hole score for each hole
          const dataHoleScore = {
              layout_holes_id: hole.layout_holes_id,
              event_entries_id: registerData[0].id,
          }

          //create hole score
          createHoleScore(dataHoleScore).then(() => {
            // console.log('hole score created')
          })

          //when all holes are created (resolvedpromises.length === layoutData.length AND layoutData is NOT NULL), redirect to scorecard
          // if(numHolesCreated === layoutData.length && layoutData !== null){

          // }else{
          //   // console.log(resolvedPromises, ' resolvedPromises ', resolvedPromises.length)
          //   console.log(' layoutData ', layoutData.length)
          //   console.log(' numHolesCreated ', numHolesCreated)
          // }
        })

        // console.log(signupObject.event_id,'signupObject.event_id')

        //redirect to event page
        router.push(`/event?event=${signupObject.event_id}`)
        // router.push(`/scorecard?scorecard=${registerData[0].id}`)
      })
    })
  }
  }
  
  

  return (
    <>
      <Meta title="Signup" />

      <Section
        bg={props.bg}
        textColor={props.textColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >
        <Container>
          {/* <SectionHeader
            title='Check in'
            subtitle='Player must already have an account. If not, please have them create one first.'
            size={1}
            spaced={true}
            className="text-center"
          /> */}
              <header
                className={
                  "SectionHeader text-center"
                }
              >
          <h1 className="font-weight-bold">
            Check in
          </h1>
          <p className="SectionHeader__subtitle mb-0">Player must already have an account. If not, please have them 
            <Link to={`auth/signup`}> create one </Link> first.
           </p>
          </header>
        </Container>

        
      </Section>

      <Container>
      <p className="text-center">Please Choose Player</p>
        <ListGroup as='ul'>
          {playersStatus !== "loading" && players && players.length > 0 && (
            players.map((item, index) => (
              isSelectedPlayer(item.id) ? <ListGroup.Item as='li' key={index} active style={{background:'#2D083F'}}>
                <p className="font-weight-bold mb-0" style={{color:'white'}}>
                  {item.name}
                </p>
              </ListGroup.Item>
              : <ListGroup.Item as='li' key={index} onClick={() => handleSetPlayer(item.id,item.name)}>
              {/* // handleSetPlayer(item.event_divisions_id,item.division_cost,item.divisions.name,item.layout_id)}> */}
                <p className="font-weight-bold mb-0">{item.name}</p>
              </ListGroup.Item>
            ))
          )}
        </ListGroup>
      </Container>
      <HrCustom />

      <Container>
      <p className="text-center">Please Choose Division</p>    
        <ListGroup as='ul'>
          {itemsStatus !== "loading" && items && items.length > 0 && (
            items.map((item, index) => (
              isSelectedDivision(item.event_divisions_id) ? <ListGroup.Item as='li' key={index} active style={{background:'#2D083F'}}>
                <p className="font-weight-bold mb-0" style={{color:'white'}}>
                  {item.divisions.name}
                </p>
              </ListGroup.Item>
              : <ListGroup.Item as='li' key={index} onClick={() => handleSetDivision(item.event_divisions_id,item.division_cost,item.divisions.name,item.layout_id)}>
                <p className="font-weight-bold mb-0">{item.divisions.name}</p>
              </ListGroup.Item>
          ))
        )}

        </ListGroup>
      </Container>
      <HrCustom />

      <HrCustom />
      
      <Container style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
        <Row>
            <Col lg={6} className="mt-4 mt-lg-0">
            <Button variant={props.buttonColor} size="lg" onClick={() => {
                handlePlayerConfirmClick()
            }}>
                Confirm!
            </Button>
            </Col>
        </Row>
      </Container>

    </>
  );
}

export default requireAuth(RegisterGuestPage);
