import React from 'react';
import { Link, useRouter } from '../util/router';
import SectionHeader from '../components/SectionHeader';
import { useAuth } from '../util/auth';
import { useEventName } from '../util/db';
import HrCustom from '../components/HrCustom';
import EventChatPostCreate from '../components/EventChatPostCreate';
import Card from 'react-bootstrap/Card';
import EventChatDisplayPosts from './EventChatDisplayPosts';

const EventChat = () => {

    const router = useRouter();
    const eventId = router.query.event_id;
    const auth = useAuth();
    const uid = auth.user ? auth.user.uid : undefined;

    const {
        data: items,
        // status: itemsStatus,
        // error: itemsError,
    } = useEventName(eventId);

    return (

        <>
            {/* back to event page */}
            
            <div className='text-right'>
                <Link to={`/event?event=${eventId}`} className="mb-3">
                    Back to Event
                </Link>
            </div>

            <SectionHeader
                title={items ? items[0].name : ''}
                subtitle={items ? items[0].date : ''}
                size={1}
                //   spaced={true}
                className="text-center mt-2 mb-0"
            />
            <HrCustom />
        
            <h6 className='text-center'>Chat</h6>

            {auth.user ? (
                <EventChatPostCreate eventId={eventId} uid={uid} />
            ) : (
                <Card>
                   <Card.Header style={{background:"#2D083F", color:"white"}}>
                        Post</Card.Header>
                    {/* card body with centered content */}
                    <Card.Body className="text-center" style={{background:'#8CABFF'}}>
                        <p>Please sign in to post</p>
                        {/* Sign in button */}
                        <Link to="/auth/signin" className="btn btn-primary">
                            Sign In
                        </Link>
                    </Card.Body>
                </Card>
            )}

        <EventChatDisplayPosts eventId={eventId}/>
            
      </>

    );
};

export default EventChat;
