import React from 'react'
import { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { useEventDate, updateEventDate } from '../util/db';


function EventManageDateChange({eventId}) {
    const [eventDate, setEventDate] = useState('');
    const [isEditted, setIsEditted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { data: items, status: itemsStatus } = useEventDate(eventId);

    useEffect(() => {
        if (itemsStatus !== "loading" && items && items.length > 0) {
            setEventDate(items[0].date);
        }
    }, [items, itemsStatus]);

    const onEventDateChange = (e) => {
        setEventDate(e.target.value);
        setIsEditted(true);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        await updateEventDate(eventId, eventDate);
        setIsSubmitted(true);
        setIsSubmitting(false);
    }

    return (
        <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3" controlId="name">
                <Row>
                    <Col>
                        <Form.Label style={{color:"#2D083F"}}>Event Date</Form.Label>
                    </Col>
                    <Col>
                        <Form.Control style={{color:"#2D083F"}} type="date" required value={eventDate} onChange={onEventDateChange} />
                    </Col>
                    <Col>
                        <Button type="submit" disabled={!isEditted || isSubmitting}>
                            {isSubmitted ? '✔' : isSubmitting ? <Spinner animation="border" /> : 'Update'}
                        </Button>
                    </Col>
                </Row>
            </Form.Group>
        </Form>
    );
}

export default EventManageDateChange