import React from 'react'
import { Container } from 'react-bootstrap'
import { useRouter } from '../util/router'
import { useState } from 'react'
import { updateGroupScorecardIsShowTotalsFalse, updateGroupScorecardIsShowTotalsTrue, useGroupScorecardIsShowTotals } from '../util/db'
import { useEffect } from 'react'


function ShowHideTotalsScorecardPage() {

    const router = useRouter();
    const scorecardId = router.query.scorecard;
    const eventId = router.query.event;
    
    const [showTotals, setShowTotals] = useState(false)

    // //get players from scorecard
    const {
        data: items,
        // status: itemsStatus,
        // error: itemsError,
    } = useGroupScorecardIsShowTotals(scorecardId);

    useEffect(() => {
        if (items) {
            setShowTotals(items[0].is_show_totals)
        }
    }, [items])

    function handleShowTotals() {
        //update showTotals in db
        updateGroupScorecardIsShowTotalsTrue(scorecardId).then(() => {
            setShowTotals(true)
            console.log('show totals')
        })
    }

    function handleHideTotals() {
        //update showTotals in db
        updateGroupScorecardIsShowTotalsFalse(scorecardId).then(() => {
            setShowTotals(false)
            console.log('hide totals')
        })
    }

    return (
        <Container>
            <h1 className="text-center">Show/Hide Totals</h1>
            <p className="text-center">Please check box if you would like to see overall points totals on the scorecard.</p>

            {showTotals && (
                <div className="form-check form-switch text-center">
                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={showTotals} onChange={handleHideTotals}/>
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Show Totals</label>
                 </div>
            )}
            {!showTotals && (
                <div className="form-check form-switch text-center">
                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={showTotals} onChange={handleShowTotals}/>
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Show Totals</label>
                </div>
            )}

            {/* bootstrap toggle switch for showTotals */}


            {/* <div className='text-center'>
                <button className='btn btn-primary' onClick={() => setShowTotals(true)}>Show Totals</button>
                 {' / '}
                <button className='btn btn-primary' onClick={() => setShowTotals(false)}>Hide Totals</button>
            </div> */}

            {/* {itemsStatus === "loading" && (
                <div className="py-5 px-3 align-self-center">
                <Spinner animation="border" variant="primary" />
                </div>
            )}
            {items && items.length === 0 && (
                <div className="text-center py-5">
                <p className="lead mb-4">No players found.</p>
                </div>
            )}
            {items && items.length > 0 && (
                <>
                    <ListGroup as="ul">
                        {items.map((item,index) => (   
                            <ScorecardPlayerDnfRow key={index} item={item} selectedPlayer={selectedPlayer} setSelectedPlayer={setSelectedPlayer}/>
                        ))}
                    </ListGroup>
                    <br />
                    <div className='text-center'>
                        <Button disabled={confirmDisabled} className='w-100' onClick={handleDnfPlayer}>
                            DNF Player
                        </Button>
                    </div>
                </>
            )} */}

            {/* //link back to scorecard */}
            <div className="py-5 px-3 align-self-center">
                <a href={`/scorecard?event=${eventId}&scorecard=${scorecardId}`}>Back to Scorecard</a>
            </div>

        </Container>
    )
}

export default ShowHideTotalsScorecardPage