import React from 'react'
import { Tabs,Tab, Container } from 'react-bootstrap';
import AdminPageEvents from './AdminPageEvents';
import AdminPageApproveTds from './AdminPageApproveTds';
import AdminPageCourses from './AdminPageCourses';
import AdminPagePayments from './AdminPagePayments';

function AdminPageContent({auth, isAdmin}) {
  return (
    isAdmin && (<Container>
        <Tabs
            defaultActiveKey="payments"
            id="uncontrolled-tab-example"
            className="mb-3 mt-3"
            fill
        >
            <Tab eventKey="events" title="Events">
                <AdminPageEvents auth={auth} />
            </Tab>
            <Tab eventKey="approve" title="TD Approval">
                <AdminPageApproveTds />
            </Tab>
            <Tab eventKey="courses" title="Courses">
                <AdminPageCourses />
            </Tab>
            <Tab eventKey="payments" title="Payments">
                <AdminPagePayments />
            </Tab>
            {/* <Tab eventKey="players" title="Edit Players">
            <EventManagePlayers items={playersItems} eventId={eventId} scoringOrderGolf={scoringOrderGolf} />
            </Tab>
            <Tab eventKey="chat" title="Chat">
            <EventManageChat eventId={eventId} uid={uid}/>
            </Tab>
            <Tab eventKey="settings" title="Settings">
            <EventManageSettings eventId={eventId} uid={uid}/>
            </Tab>
            <Tab eventKey="complete" title="Complete">
            <EventManageComplete eventId={eventId} isEventComplete={isEventComplete}/>
            </Tab> */}
        </Tabs>
    </Container>)
  )
}

export default AdminPageContent