import React from 'react'
import { useEventsAll } from '../util/db'
import { Link } from 'react-router-dom'

function AdminPageEvents({auth}) {

    //look up all events in db
    const {
        data: items,
        status: itemsStatus,
        error: itemsError,
      } = useEventsAll();
    
    return (
        //map items to table. first column event name with link to event page, 2nd column settings gear with link to event_manage, second column event date, third column event status
        <>
            {itemsError && (
                <div className="mb-3">
                ...
                </div>
            )}
            <table className="table">
                <thead>
                    <tr>
                        <th>Event Name</th>
                        <th>Manage</th>
                        <th>Date</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {itemsStatus !== "loading" && items && items.length > 0 && (
                        items.map((item, index) => (
                        <tr key={index}>
                            <td><Link to={`/event?event=${item.id}`}>{item.name}</Link></td>
                            <td><Link to={`/event_manage?event=${item.id}`}>
                                {/* //settings gear     */}
                                ⚙️
                            </Link></td>
                            <td>{item.date}</td>
                            <td>
                                {item.is_event_complete ? 'Complete' : 'Open'}
                                {!item.is_event_complete && 
                                    // delete option
                                    <Link to={`/event_delete?event=${item.id}`}>
                                        {/* trash can */}
                                        🗑️
                                    </Link>
                                }
                            </td>
                        </tr>
                        ))
                    )}
                </tbody>
            </table>
        </>
    )
}

export default AdminPageEvents