import React from "react";
import Container from "react-bootstrap/Container";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { useAuth } from "./../util/auth";
import DashboardItemsTd from "./DashboardItemsTd";
import DashboardItemsCreateEvent from "./DashboardItemsCreateEvent";
import { Tabs } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";

function DashboardSectionTd(props) {
  const auth = useAuth();
  
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={1}
          spaced={true}
          className="text-center"
        />
        
        <Tabs
            defaultActiveKey="editevent"
            id="uncontrolled-tab-example"
            className="mb-3 mt-3"
            fill
            variant="pills"
        >
            <Tab eventKey="createevent" title="Create Event">
                <DashboardItemsCreateEvent />
            </Tab>
            <Tab eventKey="editevent" title="Edit Event">
                <DashboardItemsTd auth={auth} />
            </Tab>  
        </Tabs>
      </Container>
    </Section>
  );
}

export default DashboardSectionTd;
