import React from 'react';
import Table from 'react-bootstrap/Table';
import EventsTableRowPast from './EventsTableRowPast';

function EventsTablePast({ events }) {
    
  return (
    <Table responsive className='table table-striped'>
            <thead style={{background:"#2D083F"}}>
                <tr>
                <th className='text-center' style={{color:'#ffffff', width:'10%'}}>
                        Date
                    </th>
                    <th className='text-start' style={{color:'#ffffff'}}>
                        Name
                    </th>
                    <th className='text-center' style={{color:'#ffffff', width:'10%'}}>
                        Course
                    </th>
 
                </tr>
            </thead>
            <tbody>
                {events.map((event, index) => (
                    <EventsTableRowPast key={index} event={event} /> 
                ))}
            </tbody>
        </Table>
  );
}

export default EventsTablePast;