import React, { useState,useEffect } from 'react'
import { Card, Table } from 'react-bootstrap'
import EventManageCardConfirmDeleteModal from './EventManageCardConfirmDeleteModal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from '../util/router'
import { updateScorecardIsSubmitted } from '../util/db'
import { updateScorecardNotSubmitted } from '../util/db'
import ViewScorecardHeaderRow from './ViewScorecardHeaderRow';
import ViewScorecardRow from './ViewScorecardRow'

function EventManageCardsFullCard({card,uid, eventId}) {
    const [isCardComplete,setIsCardComplete] = useState(card.is_complete)
    const numHoles = 20

    //useeffect to refresh component when card is complete
    useEffect(() => {
        setIsCardComplete(card.is_complete)
    }, [card.is_complete])
    
    function onLockCard() {
        updateScorecardIsSubmitted(card.id, card.event_id, uid)
        //set card iscomplete to true
        setIsCardComplete(true)
    }

    function onUnlockCard() {
        updateScorecardNotSubmitted(card.id, card.event_id, uid)
        //set card iscomplete to false
        setIsCardComplete(false)
    }

    return (
        <Card className='m-1 mb-5' style={{background:"#2d083F"}}>
            <Card.Header style={{backgroundColor:'#2d083F',color:"#FFFFFF"}} className='pb-0'>
                <Card.Title style={{fontWeight:'bold'}}>
                    Card {card.id}
                </Card.Title>
                <Card.Subtitle className='mb-2 text-muted'>
                    {card.group_scorecard_players.length} Players
                </Card.Subtitle>
                {!card.is_complete ? 
                    <div className='mb-2 text-muted' style={{background:"#ffffff",height:"30px",borderRadius:"10%",display:"flex",justifyContent:"center",alignItems:"center"}}>
                        🥏 Active
                    </div> : 
                    <div className='mb-2 text-muted' style={{background:"#DFF0D8",height:"30px",borderRadius:"10%",display:"flex",justifyContent:"center",alignItems:"center"}}>
                        ✔️ Verified
                    </div>
                }
            </Card.Header>
            <Card.Body className='text-center px-0 py-0'>    
                <Table size='sm' className='px-0 py-0'>
                    <tbody className='px-0 py-0' style={{background: isCardComplete ? '#DFF0D8' : '#FFFFFF'}}>
                        <tr>
                            <td>
                                Created:
                            </td>
                            <td>
                                {new Date(card.created_at).toLocaleTimeString()}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Finished: 
                            </td>
                            <td>
                                {card.completed_at ? new Date(card.completed_at).toLocaleTimeString() : 'In Progress'}
                            </td>   
                        </tr>
                        {card.completed_at && (
                            <tr>
                                <td>
                                    Time to Complete:
                                </td>
                                <td>
                                    {Math.floor((new Date(card.completed_at) - new Date(card.created_at))/60000)} minutes
                                </td>
                            </tr>   
                        )}
                    </tbody>
                </Table>
                <Table size='sm' style={{background: isCardComplete ? '#DFF0D8' : "#FFFFFF"}} className='mx-0 my-0 w-100' responsive>
                    <thead style={{background:"#2D083F", text:"white"}}>
                        <ViewScorecardHeaderRow numHoles={numHoles}/>
                    </thead>
                    <tbody>
                    {card.group_scorecard_players.map((player, index) => (
                        <ViewScorecardRow player={player} key={index} isCardComplete={isCardComplete} event={eventId}/>    
                    ))}
                    </tbody>
                </Table>
            </Card.Body>

            <Card.Footer style={{backgroundColor:'#2d083F'}}>
                <Row>
                    <Col>
                        {/* //if card is complete show lock else show unlock */}
                        {isCardComplete ? (
                            <div style={{cursor:'pointer'}} onClick={onUnlockCard}>🔐</div>
                        ):(
                            <div style={{cursor:'pointer'}} onClick={onLockCard}>🗝️</div>
                        )}
                    </Col>
                    <Col style={{display:'flex', justifyContent:'center'}}>
                        <Link to={`scorecard?scorecard=${card.id}`}>
                            👀
                        </Link>
                    </Col>
                    <Col>
                        <EventManageCardConfirmDeleteModal 
                            buttonText={'🗑️'}
                            heading={'Delete Card'}
                            bodyText={'Are you sure you want to delete this card?'}
                            cancelText={'Cancel'}
                            confirmText={'Delete'}
                            card={card}
                        />
                    </Col>
                </Row>
            </Card.Footer>
        </Card>
    )
}

export default EventManageCardsFullCard