import React from 'react'
import Form from 'react-bootstrap/Form'

// function EventCreateChooseCoursesRow({selectedDivision,selectedDivisions,setSelectedDivisions,layouts}){
function EventCreateChooseCoursesRow({courses,selectedVenue,setSelectedVenue}){

    // const handleLayoutChange = (e) => {
    //     const newSelectedDivisions = selectedDivisions.map((division) => {
    //         if (division.division_id === selectedDivision.division_id){
    //             division.layout_id = e.target.value
    //         }
    //         return division
    //     })

    //     //set selectedDivisions
    //     setSelectedDivisions(newSelectedDivisions)
    // }

    const handleVenueChange = (e) => {
        setSelectedVenue(e.target.value)
    }


    return(
        <tr>
            <td className='text-center'>
                <Form.Control as='select'
                     onChange={handleVenueChange}
                >
                    {courses.map((course, index) => (
                        <option 
                            key={index} 
                            value={course.id} 
                            name={course.course_name} 
                            id={course.id}
                        >
                            {course.course_name}
                        </option>               
                    ))}
                </Form.Control>    
            </td>
        </tr>
    )
}

export default EventCreateChooseCoursesRow