import React, { useEffect } from 'react'
import { useRouter } from '../util/router'; 
import { useState } from 'react';
import ScorecardTable from '../components/ScorecardTable';
import { useGroupScorecardPlayers } from '../util/db'
import { Alert, Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ScorecardSummary from './ScorecardSummary';
import { updateScore } from '../util/db';
import { updateScorecardIsSubmitted } from '../util/db';
import { Link } from '../util/router';
import ScorecardSettings from '../components/ScorecardSettings';
import { useAuth } from '../util/auth';
import Meta from '../components/Meta';
import HrCustom from '../components/HrCustom';
import ScorecardFooterNav from '../components/ScorecardFooterNav';

function ScorecardPage() {

  let alertMessage = 'Please enter a score for all players before moving to the next hole. If a player gets a 0, please enter 0.'

  //function which checks players, and verifies that all players have a score for the current hole
  function checkForEmptyHole() {
    let emptyHole = false
    let thisCurrentHole = currentHole
    
    //loop through players and check if any of them have a null score for this hole (hole order = currenthole)
    players.forEach((player, index) => {

      //sort thisplayersholescores by hole order
      let thisPlayersHoleScores = player.event_entries.hole_scores.sort((a, b) => a.layout_holes.hole_order - b.layout_holes.hole_order)

      //if thisplayersholescores does not have a score for this hole, set emptyHole to true
      if(thisPlayersHoleScores[thisCurrentHole-1].score === null){
        emptyHole = true 
      }
    })

    if(emptyHole){
      alert(alertMessage)
    }
    
    return emptyHole
  }

  //function to increase current hole
  function onRightNav() {

    checkForEmptyHole(players, currentHole)

    let thisMaxHole=18
    if(maxHole !== null){
      thisMaxHole = maxHole
    }
    if (currentHole < thisMaxHole) {
      setCurrentHole(currentHole + 1)
    }else{
      setShowSummary(true)
    }
  }

  //function to decrease current hole
  function onLeftNav() {

    if(showSummary){
      setShowSummary(false)
    }else{
      if (currentHole > 1) {
        setCurrentHole(currentHole - 1)
      }else{
        // console.log('Min hole reached')
      }
    }
  }

  //function to jump to hole
  function onJumpToHole(hole) {
    setCurrentHole(hole)
    setShowSummary(false)
  }

  function toggleSummary(){
    setShowSummary(!showSummary)
  }

  function onUpdateHoleScore(hole_scores_id,event_entries_id, score) {

    //array of players on this scorecard
    const updatedPlayers = [...players]
  
    //loop through players and update score
    updatedPlayers.forEach((player, index) => {

      //if player.event_entries_id matches event_entries_id, loop through hole_scores to find correct hole
      if (player.event_entries_id === event_entries_id) {

        //loop through hole_scores to find correct hole
        player.event_entries.hole_scores.forEach((hole_score, index) => {

          //if hole_scores_id matches hole_scores_id, update score in scorecard state
          if (hole_score.hole_scores_id === hole_scores_id) {
            hole_score.score = score
          }
        })
      }
    })
  }
  
  function onSubmitScorecard() {
    let eventId = players[0].event_entries.event_id
  
    // loop through players and calculate total score
    players.forEach((player, index) => {
      let total = 0
      let isDnf=player.event_entries.is_dnf

      player.event_entries.hole_scores.forEach((score, index) => {
  
        //if player does not have a hole score, set this pplayers total score to null
        if(score.score === null){
          isDnf=true
        }else{
          total += score.score
        }
      })
      player.total_score = total
      if(isDnf){
        player.total_score = null
      }

      if(!isDnf){
        updateScore(player.event_entries_id, {score: player.total_score}, eventId)
      }
  
      //set group_scorecard.is_complete to true
      updateScorecardIsSubmitted(scorecardId,eventId,auth.user.uid).then(() => {
        router.push('/event?event='+eventId)
      })
    })
    
  }
 
  const router = useRouter();
  const scorecardId = router.query.scorecard
  const [currentHole, setCurrentHole] = useState(1)
  const [maxHole, setMaxHole] = useState()
  const [players, setPlayers] = useState([])
  const [showSummary, setShowSummary] = useState(false)
  const [showSettings,setShowSettings] = useState(false)
  const { data, status } = useGroupScorecardPlayers(scorecardId)
  const [isComplete, setIsComplete] = useState(false)
  const [eventId, setEventId] = useState()
  const auth = useAuth()
  const [showTotals, setShowTotals] = useState(false)

  //on page load, check local storage for current hole. If it exists, set current hole to that value. If not, set current hole to 1
  useEffect(() => {
    const currentHoleObject = JSON.parse(localStorage.getItem('currentHole'))
    if(currentHoleObject){
      //if currenthole.scorecardid matches the current scorecard, set current hole to the stored value
      if(currentHoleObject.scorecardId === scorecardId){
        setCurrentHole(currentHoleObject.currentHole)
      }
      // else{
      //   setCurrentHole(1)
      // }
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (status === "success" && data && data.length > 0) {
      setPlayers(data)
      setMaxHole(20)//set to 20 to fix warning
      // setMaxHole(calcMaxHole(data))
      setIsComplete(data[0].group_scorecard.is_complete)
      setEventId(data[0].event_entries.event_id)
      setShowTotals(data[0].group_scorecard.is_show_totals)
    }
  }
  // , [status, data]) 
  // eslint-disable-next-line
  , [status, data]) 

  useEffect(() => {
    const currentHoleObject = {
      currentHole: currentHole,
      scorecardId: scorecardId
    }
    localStorage.setItem('currentHole', JSON.stringify(currentHoleObject))
    // eslint-disable-next-line
  } , [currentHole])


  // function to open settings component
  function onSettingsClick(){
    if(showSettings){
      setShowSettings(false)
    }else{
      setShowSettings(true)
    }
  }

  return (
    <>
      <Meta title={'Scorecard'} />
      {auth.user ?
        <>
          <Row>
            <Col md="12" className='justify-'>
              {showSummary 
                ? 
                  <ScorecardSummary eventId={eventId} currentHole={currentHole} onJumpToHole={onJumpToHole} isComplete={isComplete} maxHole={maxHole} players={players} onSubmitScorecard={onSubmitScorecard} />
                :  
                  <ScorecardTable showTotals={showTotals} scorecardId={scorecardId} isComplete={isComplete} players={players} currentHole={currentHole} onUpdateHoleScore={onUpdateHoleScore} 
                    toggleSummary={toggleSummary} showSummary={showSummary} onLeftNav={onLeftNav} onRightNav={onRightNav}
                  />    
              }
            </Col>
          </Row>
          {!showSummary &&
            <>
              <HrCustom />
              <ScorecardFooterNav onSettingsClick={onSettingsClick} isComplete={isComplete} toggleSummary={toggleSummary} eventId={eventId} />
            </>
          }

          {isComplete
            ? <Container className='mt-3'><Alert style={{background:'#BFBFBF'}} >Scorecard has been submitted. Edits are disabled.</Alert></Container>
            : <div>
                {showSettings 
                  ? 
                    <ScorecardSettings scorecardId={scorecardId} players={players} eventId={eventId}/>
                  : 
                    ''
                }
              </div>
          }
        </> 
      :
        <Container className='mt-2'>          
          <p>
            Please
            <Link to={'/auth/signin'}> Login </Link>
            to view scorecard
          </p>
        </Container>
      }


      {/* <Button 
          style={{
            position: 'fixed', 
            display: 'block',
            bottom: '30px', 
            right: '30px', 
            background: '#4477CE',
            color: '#2D083F',
            border: 'none',
            borderRadius: '50%',
            padding: '15px',
            cursor: 'pointer',
            fontSize: '30px',
            boxShadow: '2px 2px 3px #512B81',
            zIndex: '9999',
            hover: {background: '#000000'}
          }}
          onClick={() => onSettingsClick()}
        >
          ⚙️
        </Button> */}
    </>
  )
}

export default ScorecardPage