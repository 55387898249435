import React from 'react'
import Form from 'react-bootstrap/Form'

function EventCreateChooseLayoutsRow({selectedDivision,selectedDivisions,setSelectedDivisions,layouts}){

    const handleLayoutChange = (e) => {
        const newSelectedDivisions = selectedDivisions.map((division) => {
            if (division.division_id === selectedDivision.division_id){
                division.layout_id = e.target.value
            }
            return division
        })

        //set selectedDivisions
        setSelectedDivisions(newSelectedDivisions)
    }

    return(
        <tr>
            <td>
                <Form.Label>{selectedDivision.division_name}</Form.Label>
            </td>
            <td className='text-center'>
            <Form.Control as='select' onChange={handleLayoutChange}>
                    {layouts.map((layout, index) => (
                        <option key={index} value={layout.layout_id} name={layout.layout_name} id={layout.layout_id}>{layout.courses.course_name} ({layout.layout_name})</option>
                    ))}
                </Form.Control>    
            </td>
        </tr>
    )
    
}
export default EventCreateChooseLayoutsRow