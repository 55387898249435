import React from 'react'
import { useGroupScorecards } from '../util/db'
import EventManageCardsFullCard from './EventManageCardsFullCard'
import { Spinner } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function EventManageCardsFull({eventId,uid}) {
    const {
        data:cards,
        status:cardsStatus,
        error:cardsError
    } = useGroupScorecards(eventId)
    
    let allCardsEmpty = 1

    //if there are items (hook is complete, no errors)loop through items
    if(cards !== null && cards !== undefined){

        cards.forEach((item, index) => {
            //if group_scorecard_players is not empty
            if(item.group_scorecard_players.length > 0){
                allCardsEmpty = 0
            }
        })
    }

    return (
        <Row>
            {cardsStatus === 'loading' && (
                <Spinner animation="border" role="status" />
            )}
            {cardsStatus === 'error' && (
                <div>Error: {cardsError.message}</div>
            )}
            {cardsStatus !== "loading" && cards && cards.length > 0 && (
                cards.map((card, index) => (
                    card.group_scorecard_players.length !== 0 && (  
                        <Col key={index}>
                            <EventManageCardsFullCard uid={uid} card={card} key={index} eventId={eventId} />
                        </Col>
                    )
                ))
            )}
            {cardsStatus !== "loading" && cards && cards.length === 0 && (
                <Container>
                    No cards yet
                </Container>
            )}
            {cardsStatus !== "loading" && cards && cards.length !== 0 && allCardsEmpty && (
                <Container>
                    No cards yet
                </Container>
            )}
        </Row>
    )
}

export default EventManageCardsFull