import React, {useState} from 'react'
import { ListGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { createGroupScorecard, createGroupScorecardPlayers } from '../util/db';
import { useRouter } from '../util/router';
import ScorecardPlayerSelectRow from './ScorecardPlayerSelectRow';

//async function to create scorecard
// async function createScorecard (layout_id,event_entry_id,uid){
//   //get holes on layout
//   const data = await getLayoutHoles(layout_id)

//   //map through holes and create hole_score for each hole
//   const unresolvedPromises = data.map(hole => (
//     createHoleScore({
//       layout_holes_id: hole.layout_holes_id,
//       event_entries_id: event_entry_id,
//       scorekeeper_id: uid
//     })
//   ))
//   const results = await Promise.all(unresolvedPromises)
//   return results
// }


function ScorecardPlayerSelect (props){

  const [selectedPlayers, setSelectedPlayers] = useState([])
  const [confirmDisabled, setConfirmDisabled] = useState(false)
  const eventId = props.eventId
  const auth = props.auth
  const uid = auth.user ? auth.user.uid : undefined;
  const router = useRouter();

  const handleConfirmPlayers = () => {

    if(selectedPlayers.length !== 0){
      setConfirmDisabled(true)

      const dataGroupScorecard = {
          event_id: eventId,
          card_setup_user: uid,
      }

      //create group scorecard in group_scorecard table and return id
      createGroupScorecard(dataGroupScorecard).then((groupScorecardId) => {

        //loop through selected players, and add to group_scorecard_players table
        selectedPlayers.forEach((player) => {
          const dataGroupScorecardPlayers = {
              group_scorecard_id: groupScorecardId.id,
              event_entries_id: player,
          }

          //adding .then redirect rather than creating hole scores
          //create group scorecard players
          createGroupScorecardPlayers(dataGroupScorecardPlayers).then(() => {
                        //redirect to scorecard
                      router.push(`/scorecard?scorecard=${groupScorecardId.id}`)
          })

          // //create holescores for each player
          // getEventDivisionsIdFromEventEntries(player).then((eventDivisionsId) => {
              
          //     getLayoutIdFromEventDivisions(eventDivisionsId.event_divisions_id).then((layoutId) => {

          //         //create scorecard
          //         createScorecard(layoutId.layout_id,player,uid).then(() => {
                    
          //             //redirect to scorecard
          //             router.push(`/scorecard?scorecard=${groupScorecardId.id}`)
          //         })
          //     })
          // })
        })
      })
    }else{
      alert('Please select players to create a scorecard.')
    }
  }

  const availablePlayers = props.items

  return(        
    <div>
      <ListGroup as="ul">
        {availablePlayers.map((item,index) => (   
            <ScorecardPlayerSelectRow eventId={eventId} auth={auth} key={index} item={item} selectedPlayers={selectedPlayers} setSelectedPlayers={setSelectedPlayers}/>
        ))}
      </ListGroup>
      <br />
      <div className='text-center'>
        <Button disabled={confirmDisabled} className='w-100' onClick={handleConfirmPlayers}>
          Create Scorecard
        </Button>
      </div>
    </div>
  )
}

export default ScorecardPlayerSelect