import React from 'react';
import Table from "react-bootstrap/Table";
import { useGroupScorecardPlayer } from '../util/db';
import { useEffect } from 'react';

function ScorecardSummaryTable({scorecard,eventEntryId}) {
    const { data: holes,
        //  status: playersStatus, 
        //  error: playersError 
        } = useGroupScorecardPlayer(eventEntryId)
    const [holeScores, setHoleScores] = React.useState([])
    const [playersTotal, setPlayersTotal] = React.useState(0)
    const [playersNumFives, setPlayersNumFives] = React.useState(0)

    // use effect, when holes change, set holes to holes
    useEffect(() => {
        let holesArray = []
        let total = 0
        let numFives = 0

        if(holes){
            //loop through holes array. add hole_order and score to holesArray
            holes.forEach((hole, index) => {
                holesArray.push({
                    hole_order: hole.layout_holes.hole_order,
                    score: hole.score
                })
            })
        }

        //sort holes_array by hole_order
        holesArray.sort((a,b) => (a.hole_order > b.hole_order) ? 1 : ((b.hole_order > a.hole_order) ? -1 : 0))

        //get sum of scores
        holesArray.forEach((hole, index) => {
            total += hole.score
            
            if(hole.score === 5){
                numFives++
            }
        })

        //set total
        setPlayersTotal(total)

        //set num fives
        setPlayersNumFives(numFives)

        setHoleScores(holesArray)
        
    }, [holes])
    
    return (
        <Table responsive style={{ tableLayout: "auto", border: "2px solid #2D083F"}} >
            <thead style={{background:"#2D083F", text:"white"}}>
                <tr>
                    {/* <th className="text-center align-middle" style={{ color:'#FFFFFF',width: "30px", tableLayout: "auto", border: "2px solid #2D083F", position:'sticky',left:0,background:"#2D083F" }}></th> */}
                    {/* <th onClick={()=>onJumpToHole(index+1)} key={index} className="text-center align-middle" style={{ width: "30px", tableLayout: "auto", border: "2px solid #2D083F", color:"white"}}> */}
                    {/* {holes?.map((hole, index) => (
                        <th 
                            // onClick={()=>onJumpToHole(index+1)} 
                            key={index} 
                            className="text-center align-middle" 
                            style={{ 
                            width: "30px", 
                            tableLayout: "auto", 
                            border: "2px solid #2D083F", 
                            cursor:'pointer',
                            color:(index+1)%4===1 ? "#FFFFFF" : (index+1)%4===2 ? "#2D083F" : (index+1)%4===3 ? "#FFFFFF" : "#2D083F" , 
                            background: (index+1)%4===1 ? "#FF0000" : (index+1)%4===2 ? "#FFFFFF" : (index+1)%4===3 ? "#0000FF" : "#FFFF00" }}
                        >
                            {hole}
                        </th>
                    ))} */}
                    <th className="text-center align-middle" style={{ width: "30px", tableLayout: "auto", border: "2px solid #2D083F", color:"white" }}>Total</th>
                    {holeScores?.map((hole, index) => (
                        <th 
                            // onClick={()=>onJumpToHole(index+1)} 
                            key={index} 
                            className="text-center align-middle" 
                            style={{ 
                            width: "30px", 
                            tableLayout: "auto", 
                            border: "2px solid #2D083F", 
                            cursor:'pointer',
                            color:(index+1)%4===1 ? "#FFFFFF" : (index+1)%4===2 ? "#2D083F" : (index+1)%4===3 ? "#FFFFFF" : "#2D083F" , 
                            background: (index+1)%4===1 ? "#FF0000" : (index+1)%4===2 ? "#FFFFFF" : (index+1)%4===3 ? "#0000FF" : "#FFFF00" }}
                        >
                            {hole.hole_order}
                        </th>
                    ))}
                    <th className="text-center align-middle" style={{ width: "30px", tableLayout: "auto", border: "2px solid #2D083F", color:"white" }}>&#9734;</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td className="text-center align-middle" style={{ width: "30px", tableLayout: "auto", border: "2px solid #2D083F" }}>{playersTotal}</td>
                    {holeScores?.map((hole, index) => (
                        <td 
                            // onClick={()=>onJumpToHole(index+1)} 
                            key={index} 
                            className="text-center align-middle" 
                            style={{ 
                            width: "30px", 
                            tableLayout: "auto", 
                            border: "2px solid #2D083F", 
                            cursor:'pointer',
                            
                        }}
                        >
                            {hole.score}
                        </td>
                    ))}
                    
                    <td className="text-center align-middle" style={{ width: "30px", tableLayout: "auto", border: "2px solid #2D083F" }}>{playersNumFives}</td>
                </tr>
            </tbody>
        </Table>
    );
};

export default ScorecardSummaryTable;