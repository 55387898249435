import React from 'react'
//from db file
import { useIsTdApplicationPending } from '../util/db'
import Table from 'react-bootstrap/Table'
import AdminPageApproveTdsRow from './AdminPageApproveTdsRow'


function AdminPageApproveTds() {

    //useistdapplication hook
    const {
      data: items,
      // status: itemsStatus,
      // error: itemsError,
  } = useIsTdApplicationPending();

  return (
    <>
      <Table responsive className='table' >
        <thead>
          <tr>
            <th>Name</th>
            <th>Notes</th>
            <th>Approve</th>
            <th>Deny</th>
          </tr>
        </thead>
        <tbody>
        {items && items.map((item,index) => (
          <AdminPageApproveTdsRow item={item} key={index} />
        ))}
        {items && items.length === 0 && (
          <tr>
            <td colSpan="4">No TD Applications Pending</td>
          </tr>
        )}
        </tbody>
      </Table>
    </>
  )
}

export default AdminPageApproveTds