import React from 'react'
import ScorecardBeginComponent from './ScorecardBeginComponent'
import { Card, Button } from 'react-bootstrap'
import { useRouter } from "../util/router";

function EventScorecards({isLoggedIn, uid, eventId, isEventComplete, auth,canCheckIn, isCheckedInAlready, isIncompleteCards}) {
    const router = useRouter();

    return (
        <>
            {isLoggedIn 
                ? 
                    (
                        <>
                            <ScorecardBeginComponent isIncompleteCards={isIncompleteCards} isCheckedInAlready={isCheckedInAlready} canCheckIn={canCheckIn} isEventComplete={isEventComplete} eventId={eventId} isLoggedIn={isLoggedIn} userId={uid} auth={auth}/>
                        </>
                    )  
                : 
                    (
                        <>
                            <Card className="mt-2 mb-2" style={{border:'none'}}>
                                        <Card.Header
                                            as="h5"
                                            className="d-flex justify-content-between align-items-center"
                                            style={{background:"#2D083F", text:"white"}}
                                        >
                                            <Card.Title style={{color:"#ffffff"}}>
                                                Scorecard
                                            </Card.Title>
                                        </Card.Header>
                                        <Card.Body className="d-flex justify-content-center align-items-center" style={{background:"#FFFFFF"}}>
                                            <div className="align-self-center">
                                                <div>
                                                    <h4 className=" text-center">
                                                        Ready to score?
                                                        </h4>
                                                        <br />
                                                        <Button
                                                            variant="primary"
                                                            onClick={() => router.push(`/auth/signin?next=/event?event=${eventId}`)}   
                                                            // let buttonPath="/auth/signin?next=/event?event="+eventId
                                                        >
                                                            Log-In
                                                        </Button>
                                                        {'  '}
                                                        or {''}
                                                        <Button
                                                            variant="primary"
                                                            onClick={() => router.push(`/auth/signup?next=/event?event=${eventId}`)}
                                                        >
                                                            Create an Account
                                                        </Button>
                                                   
                                                </div>
                                                
                                            </div>
                                        </Card.Body>
                                        <Card.Footer
                                            as="h5"
                                            className="d-flex justify-content-between align-items-center"
                                            style={{background:"#2D083F", text:"white"}}
                                        >

                                        </Card.Footer>
                                    </Card>
                        </>
                    )
            }
        </>
    )
}

export default EventScorecards