import React from "react";
import  { usePlayerNumEntries }  from "../util/db";
import Card from "react-bootstrap/Card";
import { usePlayerScore } from "../util/db";
import { usePlayerNumEvents } from "../util/db";

function DashboardStats(props) {

    const uid = props.uid

    const playersScore = usePlayerScore(uid);
    // const playersFives = usePlayerFives(uid);
    const playersEntries = usePlayerNumEntries(uid);
    const playersEvents = usePlayerNumEvents(uid);




    return (
        <Card>
          <Card.Header
            as="h5"
            className="d-flex justify-content-between align-items-center"
            style={{background:"#2D083F", text:"white"}}
          >
            <Card.Title style={{color:"#ffffff"}}>
              Stats
            </Card.Title>
          </Card.Header>
            <Card.Body>
            <div>
                <div>
                    Total Plays: {playersEntries}
                </div>
                <div>
                    Total Events: {playersEvents}
                </div>
                <div>
                    Total Points Scored: {playersScore}
                </div>
            </div>
            </Card.Body>
        </Card>
    );
}

export default DashboardStats;
