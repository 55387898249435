import React from 'react'
import EventManageNameChange from './EventManageNameChange'
import EventManageDateChange from './EventManageDateChange'

function EventManageSettings({eventId}) {

    return (
        <>
            <EventManageNameChange eventId={eventId} />
            <EventManageDateChange eventId={eventId} />
            {/* <EventManageGolfScoringChange eventId={eventId} /> */}
        </>
    )
}

export default EventManageSettings