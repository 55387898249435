import React, { useEffect, useState } from "react";
import Meta from "./../components/Meta";
import { useRouter } from "../util/router";
import EventSection from "../components/EventSection";
import EventSideGameSection from "../components/EventSideGameSection";
import { useAuth } from "../util/auth";
import { useEventName, useEventTdId } from "../util/db";
import EventTdSection from "../components/EventTdSection";
import CtaSection from "../components/CtaSection";
import DisplayLeaderboard from "../components/DisplayLeaderboard";
import SectionHeader from "../components/SectionHeader";
import HrCustom from "../components/HrCustom";
import { Container } from "react-bootstrap";
import EventChat from "../components/EventChat";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import EventScorecards from "../components/EventScorecards";
import { useEventEntriesByEventUser } from "../util/db";
import { useGroupScorecardsByEventUser } from "../util/db";


function EventPage(props) {

    const router = useRouter()
    const auth = useAuth();
    const eventId = router.query.event
    const uid = auth.user ? auth.user.uid : '';
    const [isEventComplete,setIsEventComplete] = useState(false)
    const [eventName,setEventName] = useState('')
    const [eventDate,setEventDate]  = useState()
    const [eventCourse,setEventCourse] = useState('')
    const [scoringOrderGolf,setScoringOrderGolf] = useState(null)
    const [tdId,setTdId] = useState('')
    const [isLoggedIn,setIsLoggedIn] = useState(false)
    const [canCheckIn,setCanCheckIn] = useState(true)
    const [isIncompleteCards,setIsIncompleteCards] = useState(false)
    const [isCheckedInAlready,setIsCheckedInAlready] = useState(false)
    const [defaultActiveKey] = useState("scorecards")

    const { data: entries,
        // status: entriesStatus,
        // error: entriesError 
    } = useEventEntriesByEventUser(eventId,uid);

    const { data: scorecards,
        status: scorecardsStatus,
        // error: scorecardsError
    } = useGroupScorecardsByEventUser(eventId,uid);

    useEffect(() => {
        if(scorecardsStatus !== "loading" && scorecards ){
            scorecards.forEach(scorecard => {
                if(scorecard.group_scorecard.is_complete === false){
                    setIsIncompleteCards(true)
                    setCanCheckIn(false)
                }
            })
        } 

        if(entries && scorecards && entries.length !== scorecards.length){
            setIsCheckedInAlready(true)
            setCanCheckIn(false)
        }
    
    },[scorecards,entries,isIncompleteCards,isCheckedInAlready,canCheckIn,scorecardsStatus])



    useEffect(() => {
        if(uid !== ''){
            setIsLoggedIn(true)
        }else{
            setIsLoggedIn(false)
        }
    }, [uid])

    const {
        data: items,
        status: itemsStatus,
        // error: itemsError,
    } = useEventName(eventId);

    useEffect(() => {
        itemsStatus !== "loading" && items && items.length > 0 && (
            setEventName(items[0].name)
        )
    }, [items,itemsStatus]);

    useEffect(() => {
        itemsStatus !== "loading" && items && items.length > 0 && (
            setEventDate(new Date(items[0].date).toLocaleDateString())
        )
    }, [items,itemsStatus]);

    useEffect(() => {
        itemsStatus !== "loading" && items && items.length > 0 && (
            setEventCourse(items[0].courses.course_name)
        )
    }, [items,itemsStatus]);

    useEffect(() => {
        itemsStatus !== "loading" && items && items.length > 0 && (
            setScoringOrderGolf(items[0].scoring_order_golf)
        )
    }, [items,itemsStatus]);

    useEffect(() => {
        if(itemsStatus !== "loading" && items && items.length > 0){
            setIsEventComplete(items[0].is_event_complete)
            //if event is complete, should default to leaderboard
            // if(items[0].is_event_complete === true){
            //     setDefaultActiveKey("scoreboard")
            // }
            // console.log('isEventComplete',isEventComplete)
            // console.log(defaultActiveKey,'defaultActiveKey')
        }
    }, [items,itemsStatus]);

    const {
        data: itemsTd,
        status: itemsStatusTd,
        // error: itemsErrorTd,
    } = useEventTdId(eventId);

    useEffect(() => {
        itemsStatusTd !== "loading" && itemsTd && itemsTd.length > 0 && (
            setTdId(itemsTd[0].td_id)
        )
    }, [itemsTd,itemsStatusTd]);

    let buttonPath="/auth/signin?next=/event?event="+eventId

    return (
        <>
            <Meta title={eventName} />
            {!isLoggedIn
                && (
                    <>
                    <CtaSection
                        bg="primary"
                        textColor="light"
                        size="sm"
                        bgImage=""
                        bgImageOpacity={1}
                        title="Ready to play?"
                        subtitle=""
                        buttonText="Log In"
                        // buttonColor="#4D81F4"
                        buttonPath={buttonPath}
                        
                    />
                    <HrCustom width={'100%'} />
                    </>
                ) 
            }
            <Container>
                {isLoggedIn ? (
                    tdId === uid
                    && <EventTdSection eventId={eventId}/>
                ) : <></>}
            </Container>

            <SectionHeader
                title={eventName}
                subsubtitle={eventDate}
                subtitle={eventCourse}
                size={1}
                //   spaced={true}
                className="text-center mb-0"
            />
            <Container style={{fontWeight:'bold'}}>
                <Tabs
                //if event is complete, default to leaderboard. else, default to scorecard
                    defaultActiveKey={defaultActiveKey}
                    className="mb-3 mt-3"
                    fill
                    variant='pills'
                    // activeKey={key}
                >
                    <Tab eventKey="scorecards" title="Scorecard">
                        <EventScorecards isIncompleteCards={isIncompleteCards} isCheckedInAlready={isCheckedInAlready} canCheckIn={canCheckIn} eventId={eventId} uid={uid} isLoggedIn={isLoggedIn} isEventComplete={isEventComplete} auth={auth} />
                    </Tab>
                    <Tab eventKey="chat" title="Chat">
                        <EventChat eventId={eventId} uid={uid}/>
                    </Tab>
                    <Tab eventKey="scoreboard" title="Leaderboard">
                        <DisplayLeaderboard
                            bg="white"
                            textColor="dark"
                            size="md"
                            bgImage=""
                            bgImageOpacity={1}
                            title={eventName}
                            subtitle=""
                            eventId={eventId}
                            scoringOrderGolf={scoringOrderGolf}
                            isEventComplete={isEventComplete}>
                            
                            </DisplayLeaderboard>
                        {/* <DisplayScoreboard
                            bg="white"
                            textColor="dark"
                            size="md"
                            bgImage=""
                            bgImageOpacity={1}
                            title={eventName}
                            subtitle=""
                            eventId={eventId}
                            scoringOrderGolf={scoringOrderGolf}
                            isEventComplete={isEventComplete}
                        /> */}
                    </Tab>
                    <Tab eventKey="players" title="Players">
                        {scoringOrderGolf !== null 
                            ?
                                <EventSection
                                    bg="white"
                                    textColor="dark"
                                    size="md"
                                    bgImage=""
                                    bgImageOpacity={1}
                                    title={eventName}
                                    subtitle=""
                                    eventId={eventId}
                                    scoringOrderGolf={scoringOrderGolf}
                                    isEventComplete={isEventComplete}
                                />
                            :
                                <div>Loading...</div>
                            }
                    </Tab>

                </Tabs>
            </Container>

            <EventSideGameSection
                bg="white"
                textColor="dark"
                size="md"
                bgImage=""
                bgImageOpacity={1}
                title="Side Games"
                subtitle=""
                eventId={eventId}
            />

            {/* {isLoggedIn ? (
                // <Button 
                // style={{
                //     position: 'fixed', 
                //     display: 'block',
                //     bottom: '30px', 
                //     right: '30px', 
                //     background: '#4477CE',
                //     color: '#2D083F',
                //     border: 'none',
                //     borderRadius: '50%',
                //     padding: '15px',
                //     cursor: 'pointer',
                //     fontSize: '30px',
                //     boxShadow: '2px 2px 3px #512B81',
                //     zIndex: '9999',
                //     hover: {background: '#000000'}
                // }}
                //     onClick={onButtonClick()}
                // >
                //     ➕
                // </Button>
                ''
            ) : <>''</>} */}

        </>
    );
}

export default EventPage;
