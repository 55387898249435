import React from "react";
import Container from "react-bootstrap/Container";
// import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import SectionHeader from "./SectionHeader";
import DashboardItems from "./DashboardItems";
import { Link } from "./../util/router";
import { useAuth } from "./../util/auth";
import { getIsTd } from "../util/db";
// import DashboardItemsApplyTd from "./DashboardItemsApplyTd";
import DashboardSectionTd from "./DashboardSectionTd";
import DashboardStats from "./DashboardStats";

function DashboardSection(props) {
  const auth = useAuth();
  // const router = useRouter();

  const uid = auth.user ? auth.user.uid : null;
  const [isPlayerTd, setIsPlayerTd] = React.useState(false);

  //if uid is not null, check if user is a TD
  const isTd = uid ? getIsTd(uid) : null;


  //useeffect to update isplayertd when uid changes
  React.useEffect(() => {
    if(uid){
      isTd.then((result) => {
        setIsPlayerTd(result.is_td)
      })
    }
  }, [isTd, uid])

  const date = new Date(auth.user.created_at); // Replace with your date variable

  // Extract month, day, and year
  const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  
  // Format the date as MM-DD-YYYY
  const formattedDate = `${month}-${day}-${year}`;

  return (
    
    
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={auth.user.name}
          size={1}
          spaced={true}
          className="text-center mt-4"
        />
        <Row>
          <Col>
          <DashboardStats uid={uid} />
          </Col>
        </Row>
        
        
        <Row className="mt-3">

          <Col>
            <DashboardItems />
          </Col>
          <Col lg={6} className="mt-4 mt-lg-0">
            <Card>
              <Card.Body>
                <h5 className="mb-3">Account Info</h5>
                <div className="mt-4">
                  <div>
                    Welcome, {auth.user.name}!
                  </div>
                  <div>
                    You are signed in as <strong>{auth.user.email}</strong>.
                  </div>
                  <div>
                    Member Since: {formattedDate}

                  </div>
                  <div className="mt-3">
                    You can change your account info{` `}
                    in{` `}
                    <Link to="/settings/general">
                      <strong>settings</strong>
                    </Link>
                    .
                  </div>
                  <br />

                </div>
              </Card.Body>
            </Card>
            
            
          </Col>
        </Row>
        
        {/* {!isPlayerTd && (
          <Row>
            <Col>
              <DashboardItemsApplyTd uid={uid} />
            </Col>
          </Row>
        )} */}
        {isPlayerTd && (
          <DashboardSectionTd />
        )}

      </Container>
  );
}

export default DashboardSection;
