import React, { useEffect } from 'react'
import { useState } from 'react';
import { insertScoreEdit, updateHoleScore } from '../util/db';
import { useAuth } from '../util/auth.js';
import { useRouter } from '../util/router';
import Toast from 'react-bootstrap/Toast'
import ScorecardScoreButton from './ScorecardScoreButton.js';
import ScorecardScoreBox from './ScorecardScoreBox.js';

function ScorecardRow({player, currentHole, onUpdateHoleScore, isComplete, scorecardId,showTotals}) {
  const router = useRouter()
  const defaultScore = 3
  const auth = useAuth();
  const [showHoleInfo, setShowHoleInfo] = useState(false);
  const toggleHoleInfo = () => setShowHoleInfo(!showHoleInfo);

  //calculate this players total score
  let total = 0
  player.event_entries.hole_scores.forEach((score, index) => {
      total += score.score  
  }) 

  
  //state to hold response score from db
  const [dbResponseScore, setDbResponseScore] = useState(null)

  //state to hold local state score
  const [thisScore, setThisScore] = useState()
  
  // Returned status value will be "idle" if we're waiting on
  // the uid value or "loading" if the query is executing.
  const uid = auth.user ? auth.user.uid : undefined;

  function onViewEdits() {
    //redirect to /holescoreedits
    router.push(`/holescoreedits?hole=${hole_scores_id}&event=${player.event_entries.event_id}`)
  }

  //function to log hole_score_edit into databse
  function logHoleScoreEdit(hole_score_id,newScore,uid) {
    insertScoreEdit({hole_scores_id:hole_score_id,score:newScore,editor_id: uid})
  }

  function onClickLockedScore() {
    alert('This scorecard is locked. Please contact the event organizer to make changes.')
  }

  function getHolescoreId() {
    let hole_scores_id = null
    player.event_entries.hole_scores.map((hole_score, index) => {
      if (hole_score.layout_holes.hole_order === currentHole) {
        hole_scores_id = hole_score.hole_scores_id
      }
      return hole_scores_id
    })  
    return hole_scores_id
  }

  function onLeftScore() {
    let newScore = null

    if(thisScore === undefined || thisScore === null){
      // console.log('score was null. Set to default')
      newScore = defaultScore-1
      if(newScore === 4){
        newScore = 3
      }
      setThisScore(newScore)
      updateHoleScore(hole_scores_id,event_entries_id,{score: newScore}).then((res)=>{
        setDbResponseScore(res[0].score)
      })
      onUpdateHoleScore(hole_scores_id,event_entries_id,newScore)
      logHoleScoreEdit(hole_scores_id,newScore,uid)
    }else if (thisScore > 0) {
      // console.log('score was greater than 0. Allow decrement')
      newScore = thisScore - 1
      if(newScore === 4){
        newScore = 3
      }
      setThisScore(newScore)
      updateHoleScore(hole_scores_id,event_entries_id,{score: newScore}).then((res)=>{
        setDbResponseScore(res[0].score)
      })
      onUpdateHoleScore(hole_scores_id,event_entries_id,newScore)
      logHoleScoreEdit(hole_scores_id,newScore,uid)
    }
  }

  function onRightScore() {
    
    let newScore = null

    if(thisScore === undefined || thisScore === null){
      // console.log('score was null. Set to default')
      newScore = defaultScore+1
      if(newScore === 4){
        newScore = 5
      }
      setThisScore(newScore)
      updateHoleScore(hole_scores_id,event_entries_id,{score: newScore}).then((res)=>{
        setDbResponseScore(res[0].score)
      })
      onUpdateHoleScore(hole_scores_id,event_entries_id,newScore)
      logHoleScoreEdit(hole_scores_id,newScore,uid)
    }else if (thisScore < 5){
      newScore = thisScore + 1
      if(newScore === 4){
        newScore = 5
      }
      setThisScore(newScore)
      updateHoleScore(hole_scores_id,event_entries_id,{score: newScore}).then((res)=>{
        setDbResponseScore(res[0].score)
      })
      onUpdateHoleScore(hole_scores_id,event_entries_id,newScore)
      logHoleScoreEdit(hole_scores_id,newScore,uid)
    }
  }

  function onClickScore() {
    if(thisScore === undefined || thisScore === null){
      let newScore = defaultScore
      setThisScore(newScore)
      updateHoleScore(hole_scores_id,event_entries_id,{score: newScore}).then((res)=>{
        setDbResponseScore(res[0].score)
      })
      onUpdateHoleScore(hole_scores_id,event_entries_id,newScore)
      logHoleScoreEdit(hole_scores_id,newScore,uid)
    }else{
      let newScore = null
      setThisScore(newScore)
      updateHoleScore(hole_scores_id,event_entries_id,{score: newScore}).then((res)=>{
        setDbResponseScore(res[0].score)
      })
      onUpdateHoleScore(hole_scores_id,event_entries_id,newScore)
      logHoleScoreEdit(hole_scores_id,newScore,uid)
    }
  }

  //use effect to set dbResponseScore back to null if currentHole changes
  
  // useEffect(() => {
  //   setDbResponseScore(null)
  // }, [currentHole])


  

  useEffect(() => {
    function getHolescore() {
      const this_hole_score = player.event_entries.hole_scores.filter(
        (hole_score) => hole_score.layout_holes.hole_order === currentHole
      )
      //if this_hole_score[0] is not undefined, return it
      if(this_hole_score[0] !== undefined){
      return this_hole_score[0].score
      }
    }
    
    //if player.event_entries.holes_scores is not empty
    if(player.event_entries.hole_scores.length !== 0){
      setThisScore(getHolescore())  
    }

    //reset dbResponseScore to null
    setDbResponseScore(null)

  }, [currentHole, player.event_entries.hole_scores])

  const event_entries_id = player.event_entries_id
  const hole_scores_id=getHolescoreId()

  // console.log('player',player)

  return (
    <>
    <tr>
      <td style={{verticalAlign:'middle', padding:'20px 0', fontSize:'20px',fontWeight:'bold',fontStyle:'italic',color:'#2D083F'}} className='pl-1'>
        <div style={{ 
          // maxWidth:"150px",
        // overflowY: "auto" 
        }}>
        {player.event_entries.users_public.name ? player.event_entries.users_public.name : ''}
        {showTotals ? <span style={{fontSize:'12px',fontWeight:'normal',color:'#2D083F'}}> ({total})</span> : null}
        <span onClick={toggleHoleInfo} className="mb-2 ml-2">
          ℹ️
        </span>
        </div>
      </td>
      {isComplete
        ?
          <td onClick={() => onClickLockedScore()}>
            <ScorecardScoreButton backgroundColor={'#BFBFBF'} textColor={'#2D083F'} text={'-'} />
          </td>
        :
          hole_scores_id !== null 
            ?
              <td onClick={() => onLeftScore()}>
                <ScorecardScoreButton backgroundColor={'#4D81F4'} textColor={'#d9e3ff'} text={'-'} />
              </td>
            :
              <td onClick={() => onLeftScore()}>
                <ScorecardScoreButton backgroundColor={'red'} textColor={'#d9e3ff'} text={'-'} />
              </td>
      }        
      {isComplete
        ?
          <td style={{textAlign:'center',width:'30px'}} onClick={()=>onClickLockedScore()}>
            <ScorecardScoreBox backgroundColor={'#ffffff'} textColor={'#2D083F'} text={thisScore} />
          </td>
        :      
          thisScore === null || thisScore === undefined 
            ? 
              hole_scores_id !== null ? //if hole_scores_id is not null, then the hole is not present in the database
                
                <td style={{textAlign:'center'}} onClick={()=>onClickScore()}>
                  <ScorecardScoreBox backgroundColor={'#ffffff'} textColor={'#BFBFBF'} text={'?'} />
                </td>
                :

                <td style={{textAlign:'center'}} onClick={()=>onClickScore()}>
                  <ScorecardScoreBox backgroundColor={'red'} textColor={'#d9e3ff'} text={''} />
                </td>
            : 
              <>
                {dbResponseScore !== null ?
                  
                  <td style={{textAlign:'center'}} onClick={()=>onClickScore()}>
                    <ScorecardScoreBox backgroundColor={'ffffff'} textColor={'#2D083F'} text={thisScore} />
                  </td>
                : 
                  
                  <td style={{textAlign:'center'}} onClick={()=>onClickScore()}>
                    <ScorecardScoreBox backgroundColor={'#ffffff'} textColor={'#2D083F'} text={thisScore} />
                  </td>
                }
              </>     
      }
      
      {isComplete 
        ?
        // style={{textAlign:'left'}}
          <td onClick={()=>onClickLockedScore()}>
            <ScorecardScoreButton backgroundColor={'#BFBFBF'} textColor={'#2D083F'} text={'+'} />
          </td>
        :
          hole_scores_id !== null ?

            <td onClick={() => onRightScore()}>
              <ScorecardScoreButton backgroundColor={'#4D81F4'} textColor={'#d9e3ff'} text={'+'} />
            </td>
            : 
              <td onClick={() => onRightScore()}>
                <ScorecardScoreButton backgroundColor={'red'} textColor={'#d9e3ff'} text={'+'} />
              </td>
      }
        
    </tr>
    <tr>
      <td colSpan={4}>
      <Toast delay={7000} autohide show={showHoleInfo} onClose={toggleHoleInfo}>
          <Toast.Header>
            {/* <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            /> */}
            {player.event_entries.hole_scores.map((hole_score, index) => {
              if (hole_score.layout_holes.hole_order === currentHole) {
                return (
                  <div key={index}>
                    <strong className="me-auto">Hole {hole_score.layout_holes.holes.hole_name}</strong>
                  </div>
                )
              }
              return (
                null
              )
            })}
          </Toast.Header>
          <Toast.Body>
            {player.event_entries.hole_scores.map((hole_score, index) => {
              if (hole_score.layout_holes.hole_order === currentHole) {
                return (
                  <div key={index}>
                    {hole_score.layout_holes.holes.hole_description ? hole_score.layout_holes.holes.hole_description : ''}
                  </div>
                )
              }
              return (
                null
              )
            })}
            <div onClick={onViewEdits} style={{cursor:'pointer',float:'right'}}>
              View Edits
            </div>
          </Toast.Body>
        </Toast>
      </td>
    </tr>
    </>
  )
}

export default ScorecardRow