import React from 'react'
import Card from 'react-bootstrap/Card'
import { Link } from 'react-router-dom'

function DashboardItemsCreateEvent() {
  return (
    <Card className="text-center mt-3">
        <Card.Header
            as="h5"
            className="d-flex justify-content-between align-items-center "
            style={{background:"#2D083F", text:"white"}}
        >
            <Card.Title style={{color:"#ffffff"}}>
                Create Event
            </Card.Title>
        </Card.Header>
        <Card.Body>
            <Link to="/event_create">
                <button style={{backgroundColor:'#4D81F4'}} className="btn btn-primary">New Event</button>
            </Link>
        </Card.Body>
    </Card>
  )
}

export default DashboardItemsCreateEvent