import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { deleteHoleScores,deleteEventEntry } from '../util/db';
import { useQueryClient } from 'react-query';

function ConfirmDeletePlayerModal(props) {
  const [show, setShow] = useState(false);
  const queryClient = useQueryClient();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleConfirm = () => {

    //remove scorecard from db
    deleteHoleScores(props.eventEntryId).then((res)=>{

      //remove event entry id from event entry table
      deleteEventEntry(props.eventEntryId,props.eventId,props.eventDivisionsId).then((res)=>{
        // console.log('deleted event signup')
        //refresh query usePlayersByEvent event_id, "use_players"]
        queryClient.invalidateQueries([props.eventId,'use_players'])

        //refresh page for now, fix later (score box shows empty for a different player when deleting this player)
        window.location.reload()

      }).catch((err)=>{
        // console.log('error deleting event entry')
        console.log(err)
      })

    }).catch((err)=>{
      console.log('error deleting hole scores')
    })


    
    //close modal
    handleClose()
  }

  return (
    <>
      <div style={{cursor:'pointer'}} onClick={handleShow}>
        {props.buttonText}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.heading?props.heading:''}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.bodyText?props.bodyText:'Are you sure?'}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {props.cancelText?props.cancelText:'Cancel'}
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            {props.confirmText?props.confirmText:'Confirm'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfirmDeletePlayerModal;