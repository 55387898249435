import React from "react";
import Meta from "./../components/Meta";
import { useRouter } from "../util/router";
import { useUsersName } from "../util/db";
import Container from "react-bootstrap/Container";
import Section from "../components/Section";
import SectionHeader from "../components/SectionHeader";
import PlayersEvents from "../components/PlayersEvents";
import DashboardStats from "../components/DashboardStats";

function PlayerPage(props) {

    const router = useRouter()
    const uid = router.query.id
    
    function checkForName(){
        if(usersName.length === 0){
          return false
        }else{
          return true
        }
      }

    //for users name
    const {
        data: usersName = [],
        // status: usersNameStatus,
        // error: usersNameError,
    } = useUsersName(uid);

  return (
    <>
      <Meta title="Player" />

      <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          size={1}
          spaced={true}
          className="text-center"
        />
        {checkForName() ? <center><h1>{usersName[0].name}</h1></center> : <></>}      
      </Container>
      </Section>
      
        {/* <Container style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}> */}
        <Container>
              <DashboardStats uid={uid}/>
              <div className="mb-2"></div>
              <PlayersEvents uid={uid}/>
        </Container>
    </>
  );
}

export default PlayerPage;
