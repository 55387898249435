import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { removeGroupScorecardPlayer } from '../util/db'
// import { useQueryClient } from 'react-query';

function EventManageCardConfirmDeleteModal(props) {
  const [show, setShow] = useState(false);
  const card=props.card
  // const queryClient = useQueryClient();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  

  async function handleConfirmDelete() {

    // async function deleteHoleScoresForPlayer(player) {
    //   deleteHoleScores(player.event_entries.id).then((res)=>{
    //       // console.log('deleted hole scores',player.event_entries.users_public.name)
    //     }).catch((err)=>{
    //       console.log('error deleting hole scores')
    //   })
    // }

    async function removePlayerFromScorecard(player) {
      //remove player from group_scoracard_players
      removeGroupScorecardPlayer(player.id).then((res)=>{
        // console.log('removed group_scorecard_player',player.event_entries.users_public.name)
      }).catch((err)=>{
        console.log('error removing player from group_scorecard_players')
      })
    }

    // async function removePlayersScore(player) {
    //   //remove players final score
    //   updateScore(player.event_entries.id,{score:null},card.event_id).then((res)=>{
    //       // console.log('updated score to null',player.event_entries.users_public.name)
    //   }).catch((err)=>{
    //       console.log('error updating score to null')
    //   })
    // }

    // async function removePlayersDnf(player) {
      
    //   // if player was marked as DNF, unmark them
    //   if(player.event_entries.is_dnf ===true){

    //     updateDnf(player.event_entries.id, {is_dnf: 'false'}).then((res)=>{
    //         // console.log('updated is_dnf to false')
    //     }).catch((err)=>{
    //         console.log('error updating is_dnf to false')
    //     })
    //   }
    // }

    //loop through players and remove each from group scorecard. When promises are resolved, continue.
    const unresolvedPromises = card.group_scorecard_players.forEach((player, index) => {
      removePlayerFromScorecard(player)
    })

    await Promise.all(unresolvedPromises).then((res)=>{
        // removeGroupScorecard(card.id,card.event_id).then((res)=>{
        //   console.log('removed group_scorecard')
        // }).catch((err)=>{
        //   console.log('error removing group_scorecarda')
        // } )

        //invaldidate query
        // queryClient.invalidateQueries(['group_scorecards', card.event_id])

      }
    ).catch((err)=>{
      console.log('error removing group_scorecardb')
    })

    handleClose()
  }

  return (
    <>
      <div variant="primary" onClick={handleShow} style={{cursor:'pointer',display:'flex', justifyContent:'right'}}>
        {props.buttonText}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.heading?props.heading:''}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.bodyText?props.bodyText:'Are you sure?'}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {props.cancelText?props.cancelText:'Cancel'}
          </Button>
          <Button variant="primary" onClick={handleConfirmDelete}>
            {props.confirmText?props.confirmText:'Confirm'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EventManageCardConfirmDeleteModal;