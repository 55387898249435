// import { ResponsiveEmbed } from "react-bootstrap";
import {
  useQuery,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import supabase from "./supabase";

// React Query client
const client = new QueryClient();

/**** USERS ****/

// Fetch user data
// Note: This is called automatically in `auth.js` and data is merged into `auth.user`

//original hook
export function useUser(uid) {
  // Manage data fetching with React Query: https://react-query.tanstack.com/overview
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["user", { uid }],
    // Query function that fetches data
    () =>
      supabase
        .from("users")
        .select(`*, customers ( * )`)
        .eq("id", uid)
        .single()
        .then(handle),
    // Only call query function if we have a `uid`
    { enabled: !!uid }
  );
}

//useuser but without custmers(*)
// export function useUser(uid) {
//   // Manage data fetching with React Query: https://react-query.tanstack.com/overview
//   return useQuery(
//     // Unique query key: https://react-query.tanstack.com/guides/query-keys
//     ["user", { uid }],
//     // Query function that fetches data
//     () =>
//       supabase
//         .from("users")
//         .select(`*`)
//         .eq("id", uid)
//         .single()
//         .then(handle),
//     // Only call query function if we have a `uid`
//     { enabled: !!uid }
//   );
// }

// get events
export function useItems() {
  return useQuery(
    ["itemskey"],
    () =>
      supabase
        .from("events")
        .select()
        .then(handle)
  );
}

//async user,event_id,post into forum_post table
export async function createForumPost(data) {
  const response = await supabase.from("forum_post").insert([data]).then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["forum_post"]);
  await client.invalidateQueries(["forumpostskey",data.event_id]);
  await client.invalidateQueries(["forumpostsnothiddenkey",data.event_id]);
  
  return response;
}





//get forum posts for this event hook
export function useForumPosts(eventId) {
  return useQuery(
    ["forumpostskey",eventId],
    () =>
      supabase
        .from("forum_post")
        .select(`id,post,created_at,user,users_public(name),is_hidden`)
        .eq("event_id", eventId)
        .order("created_at", { ascending: false })
        .then(handle)
  );
}

//get form posts where is_hidden = false
export function useForumPostsNotHidden(eventId) {
  return useQuery(
    ["forumpostsnothiddenkey",eventId],
    () =>
      supabase
        .from("forum_post")
        .select(`id,post,created_at,user,users_public(name)`)
        .eq("event_id", eventId)
        .eq("is_hidden", false)
        .order("created_at", { ascending: false })
        .then(handle)
  );
}

// update a forum post to be hidden
export async function hideForumPost(post_id,event_id) {
  const response = await supabase
    .from("forum_post")
    .update({ is_hidden: true })
    .eq("id", post_id)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["forum_post"]);
  await client.invalidateQueries(["forumpostskey",event_id]);
  return response;
}

// update a forum post to not be hidden
export async function unHideForumPost(post_id,event_id) {
  const response = await supabase
    .from("forum_post")
    .update({ is_hidden: false })
    .eq("id", post_id)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["forum_post"]);
  await client.invalidateQueries(["forumpostskey",event_id]);
  return response;
}

export function usePastEvents() {
  return useQuery(
    ["itemspastkey"],
    () =>
      supabase
        .from("events")
        .select("date,name,courses(course_name),id")
        .eq("is_event_complete", true)
        .order("date", { ascending: false })
        .then(handle)
  );
}

export function useUpcomingEvents() {
  return useQuery(
    ["itemskeyupcoming"],
    () =>
      supabase
        .from("events")
        .select("id,date,name,courses(course_name)")
        //where is_event_complete=false
        .eq("is_event_complete", false)
        .order("date", { ascending: true })
        .then(handle)
  );
}

// function getUsersName(uid){
//   return supabase.from("users_public").select("name").eq("id", uid).single().then(handle);
// }

export function useUsersName(uid) {
  return useQuery(
    ["usenamekey", uid],
    () =>
      supabase
        .from("users_public")
        .select(`name`)
        .eq("id", uid)
        .then(handle)
  );
}

export function useUsersPublicName(uid) {
  return useQuery(
    ["usenamekey", uid],
    () =>
      supabase
        .from("users_public")
        .select(`name`)
        .eq("id", uid)
        .limit(1)
        .single()
        .then(handle)
  );
}

export function useUsersPhone(uid) {
  return useQuery(
    ["usephonekey", uid],
    () =>
      supabase
        .from("users")
        .select(`phonenumber`)
        .eq("id", uid)
        .then(handle)
  );
}

export function usePlayerName(uid) {
  // console.log('uid', uid)
  return useQuery(
    ["useplaynamekey", uid],
    () =>
      supabase
        .from("event_entries")
        .select(`id,users_public(name)`)
        .eq("id", uid)
        .then(handle)
  );
}

//hook to return value of users table is_td column
export function useIsTd(uid) {
  return useQuery(
    ["useistdkey", uid],
    () =>
      supabase
        .from("users")
        .select(`is_td`)
        .eq("id", uid)
        .then(handle)
  );
}

//hook to "select id, name from divisions"
export function useDivisions() {
  return useQuery(
    ["usedivisionskey"],
    () =>
      supabase
        .from("divisions")
        .select(`id,name`)
        .then(handle)
  );
  // console.log(queryResult)
  // return queryResult
}



//non hook to retgurn value of users table is_td column
export function getIsTd(uid) {
  return supabase
    .from("users")
    .select(`is_td`)
    .eq("id", uid)
    .single()
    .then(handle);
}

export function useEventDivisions(eventId) {
  return useQuery(
    [eventId, "eventdivisions"],
    () =>
      supabase
        .from("event_divisions")
        .select(`event_divisions_id,division_id,division_cost, divisions(name,bg_color,text_color),layout_id`)
        .eq("event_id", eventId)
        .order("division_id", { ascending: true })
        .then(handle)
  );
}

//list of events a player is signed up for
export function useEventsByOwner(user_id) {
  return useQuery(
    [user_id,"eventsbyowner"],
    () =>
      supabase
        .from("event_entries")
        .select(`event_id,event_divisions_id,events(name),score`)
        .eq("user_id", user_id)
        .order("created_at", { ascending: false })
        .then(handle),
    // { enabled: !!owner }
  );
}

//list of events a player is signed up for
export function useEventsByTd(user_id) {
  return useQuery(
    [user_id,"eventsbytd"],
    () =>
      supabase
        .from("events")
        .select(`id,name`)
        .eq(`td_id`, user_id)
        .order("created_at", { ascending: false })
        .then(handle),
    // { enabled: !!owner }
  );
}

export function useEventsAll() {
  return useQuery(
    ["eventsallkey"],
    () =>
      supabase
        .from("events")
        .select(`id,name,date,is_event_complete`)
        .order("created_at", { ascending: false })
        .then(handle),
   );
}

//list of players signed up for an event
export function usePlayersByEvent(event_id){

  return useQuery(
    [event_id, "use_players"],
    () => 
    supabase
      .from("event_entries")
      .select(`id,user_id,users_public(name),id,score,event_divisions_id,event_divisions(divisions(name)),is_dnf,is_dropped`)
      .eq("event_id", event_id)
      .order('users_public(name)')
      .order('id')
      .then(handle),
  )
}

//list of players signed up for an event and division
export function usePlayersByEventDivision(event_id,event_divisions_id){
  
  return useQuery(
    ["use_players_division",event_id, event_divisions_id],
    () => 
    supabase
      .from("event_entries")
      .select(`user_id,users_public(name),id,score,event_divisions_id,event_divisions(divisions(name)),points,payouts(payout_amount),is_dnf,is_dropped,group_scorecard_players(event_entries_id,group_scorecard_id)`)
      .eq("event_id", event_id)
      .eq("event_divisions_id", event_divisions_id)
      .order('is_dnf')
      .order('score', { ascending: true })
      .order('users_public(name)', {ascending: true})
      .then(handle),
  )
}

//list of players signed up for an event and division
export function usePlayersByEventDivisionNotGolf(event_id,event_divisions_id){
  
  return useQuery(
    ["use_players_division",event_id, event_divisions_id],
    () => 
    supabase
      .from("event_entries")
      .select(`user_id,users_public(name),id,score,event_divisions_id,event_divisions(divisions(name)),points,payouts(payout_amount),is_dnf,is_dropped,group_scorecard_players(event_entries_id,group_scorecard_id)`)
      .eq("event_id", event_id)
      .eq("event_divisions_id", event_divisions_id)
      .order('is_dnf')
      .order('score', {ascending: false})
      .order('users_public(name)', {ascending: true})
      .then(handle),
  )
}

///this wasnt working because users(name) wasnt publicly viewable due to RLS. Needs to link to the public users table.
//list of players signed up for an event
// export function usePlayersByEvent(event_id){
//   return useQuery(
//     [event_id, "use_players"],
//     () => 
//     supabase
//       .from("event_entries")
//       .select(`user_id,users(name)`)
//       .eq("event_id", event_id)
//       .then(handle),
//   )
// }

//look up players name from event entry id (non hook)
export function getPlayerNameByEntryId(event_entries_id){
  console.log('getting players name')
  return supabase
  .from("event_entries")
  .select(`user_id,users_public(name)`)
  .eq("id", event_entries_id)
  .then(handle);
}

// look up players name from event entry id (hook)
export function usePlayerNameByEntryId(event_entries_id){
  // console.log('getting players name', event_entries_id)
  return useQuery(
    ["event_entries_idpname", event_entries_id],
    () =>
    supabase
      .from("event_entries")
      .select(`user_id,users_public(name)`)
      .eq("id", event_entries_id)
      .then(handle),
  )
}


//list of players in a side game
export function useSideGamePlayersBySideGame(side_game_id){
  return useQuery(
    ["side_game_id", side_game_id],
    () => 
    supabase
      .from("side_game_entries")
      .select(`user_id,users_public(name)`)
      .eq("side_game_id", side_game_id)
      .then(handle),
  )
}





export function useEventSideGames(eventId) {
  return useQuery(
    ["eventsidegameskey"],
    () =>
      supabase
        .from("side_game")
        .select(`id,side_game_name,side_game_price`)
        .eq("event_id", eventId)
        .then(handle)
  );
}

export function usePlayerFives(user_id) {
  //get all event entries ids for a user
  const result =
  useQuery(
    ["playerfiveskey", user_id],
    () =>
      supabase
        .from("event_entries")
        .select(`id`)
        .eq("user_id", user_id)
        .then(handle)
  );

  let eventEntries = result.data
  // let totalFives = 0

  //get all hole scores for each event entry. use an object 
  //make sure to get all of the results before continuing
  let holeScores = [];
if (eventEntries) {
  // Collect all promises
  const promises = eventEntries.map(entry => {
    return supabase
      .from("hole_scores")
      .select(`score`)
      .eq("event_entries_id", entry.id)
      .eq("score", 5)
      .then(handle)
      .then(data => {
        // Loop through data array and add each row to hole scores array
        data.forEach(row => {
          holeScores.push(row.score);
        });
      });
  });

  // Wait for all promises to resolve
  Promise.all(promises).then(() => {
    return holeScores; // Return the holeScores array
  });
}





  console.log(holeScores, 'holeScores')
    

  return holeScores
}

//get players score for all events
export function usePlayerScore(user_id) {
  let result = useQuery(
    ["playerscorekey", user_id],
    () =>
      supabase
        .from("event_entries")
        .select(`score`)
        .eq("user_id", user_id)
        .then(handle)
  );
  let scores = result.data
  let total = 0

  if (scores) {
    scores.forEach(score => {
      total += score.score
    })
  }

  return total
}

export function usePlayerNumEntries(user_id) {
  let result = useQuery(
    ["playerscorekey", user_id],
    () =>
      supabase
        .from("event_entries")
        .select(`score`)
        .eq("user_id", user_id)
        .then(handle)
  );
  let scores = result.data
  let total = 0

  if (scores) {
    scores.forEach(score => {
      total += 1
    })
  }

  return total
}

//get total number of events a player has played in
export function usePlayerNumEvents(user_id) {
  let result = useQuery(
    ["playernumeventskey", user_id],
    () =>
      supabase
        .from("event_entries")
        .select(`event_id`)
        .eq("user_id", user_id)
        .then(handle)
  );

  //create integer array of event ids
  let eventIds = []
  if (result.data) {
    result.data.forEach(event => {
      eventIds.push(event.event_id)
    })
  }

  //remove duplicate entries frome eventIds
  let uniqueEventIds = [...new Set(eventIds)]

  

return uniqueEventIds.length
}

//get players score for a all events, non hook
export function getPlayerScore(user_id) {
  return supabase
    .from("event_entries")
    .select(`score`)
    .eq("user_id", user_id)
    .then(handle)
}


export function useEventName(eventId) {
  return useQuery(
    ["eventIdname",eventId],
    () =>
      supabase
        .from("events")
        .select("name,date,scoring_order_golf,is_event_complete,is_rebuys,courses(course_name)")
        .eq("id", eventId)
        .then(handle)
  );
}

export function useEventDate(eventId) {
  return useQuery(
    ["eventIddate",eventId],
    () =>
      supabase
        .from("events")
        .select("date")
        .eq("id", eventId)
        .then(handle)
  );
}

export function updateEventDate(eventId, date) {
  return supabase
    .from("events")
    .update({ date })
    .eq("id", eventId)
    .then(handle);
}


//get scoring order golf (non hook)
export function getScoringOrderGolf(eventId) {
  return supabase
    .from("events")
    .select("scoring_order_golf")
    .eq("id", eventId)
    .single()
    .then(handle);
}
//get scoring order hook
export function useScoringOrderGolf(eventId) {
  return useQuery(
    ["scoringordergolfkey",eventId],
    () =>
      supabase
        .from("events")
        .select("scoring_order_golf")
        .eq("id", eventId)
        .then(handle)
  );
}

//in devlopment
export function useEventTdId(eventId) {
  return useQuery(
    ["eventIdtd",eventId],
    () =>
      supabase
        .from("events")
        .select("td_id")
        .eq("id", eventId)
        .then(handle)
  );
}

//in devlopment
export function useEventDetails(eventId) {
  return useQuery(
    ["eventdetailskey"],
    () =>
      supabase
        .from("events")
        .select(`name, date`)
        .eq("id", eventId)
        .then(handle)
  );
}

export function useLayoutIdFromEventDivisions(eventDivisionsId) {
  return useQuery(
    ["layoutidfromeventdivisionskey"],
    () =>
      supabase
        .from("event_divisions")
        .select("layout_id")
        .eq("event_divisions_id", eventDivisionsId)
        .then(handle)
  );
}

export function useEventDivisionsIdFromEventEntries(eventEntriesId) {
  return useQuery(
    ["eventdivisionsidfromevententrieskey"],
    () =>
      supabase
        .from("event_entries")
        .select("event_divisions_id")
        .eq("id", eventEntriesId)
        .then(handle)
  );
}

export function useScorecard(eventEntryId) {
  // console.log(eventEntryId, 'eventEntryId')
  
  const result = 
  useQuery(
    ["eventscorecardskey",eventEntryId],
    () =>
      supabase
        .from("hole_scores")
        .select(`hole_scores_id, score, layout_holes_id, layout_holes(holes(hole_name,hole_par,hole_description,hole_setup_notes,hole_length),hole_order, layout_id)`)
        .eq("event_entries_id", eventEntryId)
        .order("layout_holes(hole_order)")
        .then(handle)
  );
  // console.log(result)
  return result
}

//non hook to get all event_entries for a certain event
export function getEventEntries(event_id) {
  return supabase
    .from("event_entries")
    .select(`id,user_id,users_public(name),id,score,event_divisions_id,event_divisions(divisions(name)),is_dnf,is_dropped`)
    .eq("event_id", event_id)
    .order('users_public(name)')
    .order('id')
    .then(handle)
}



//hook to get all event entries for a certain event
export function useEventEntries(event_id) {
  return useQuery(
    ["evententrieskeyy",event_id],
    () =>
      supabase
        .from("event_entries")
        // .select(`id,user_id,users_public(name),id,score,event_divisions_id,event_divisions(divisions(name)),is_dnf,is_dropped`)
        .select(`id`)
        .eq("event_id", event_id)
        // .order('users_public(name)')
        .order('id')
        .then(handle)
  );
}

export function useEventEntriesScoreboard(event_id) {
  return useQuery(
    ["evententrieskeyy",event_id],
    () =>
      supabase
        .from("event_entries")
        // .select(`id,user_id,users_public(name),id,score,event_divisions_id,event_divisions(divisions(name)),is_dnf,is_dropped`)
        .select(`id,
          is_dnf,
          user_id,
          users_public(name),
          hole_scores(
            hole_scores_id,
            score,
            layout_holes_id,
            layout_holes(
              hole_order,
              hole_id,
              holes(hole_name,hole_length,hole_description,hole_par)
            ))
          `)
        .eq("event_id", event_id)

        //where hole scores.length > 0
        .not("hole_scores", "is", null)
        // .order('users_public(name)')
        .order('id')
        .then(handle)
  );
}

export function useEventEntriesScoreboardByDiv(event_id) {
  return useQuery(
    ["evententrieskeyy",event_id],
    () =>
      supabase
        .from("event_entries")
        // .select(`id,user_id,users_public(name),id,score,event_divisions_id,event_divisions(divisions(name)),is_dnf,is_dropped`)
        .select(`id,
        event_divisions(divisions(name)),event_divisions_id,
          is_dnf,
          users_public(name),
          user_id,
          hole_scores(
            hole_scores_id,
            score,
            layout_holes_id,
            layout_holes(
              hole_order,
              hole_id,
              holes(hole_name,hole_length,hole_description,hole_par)
            ))
          `)
        .eq("event_id", event_id)

        //where hole scores.length > 0
        .not("hole_scores", "is", null)
        // .order('users_public(name)')
        .order('event_divisions_id')
        .then(handle)
  );
}

//hook to get group_scorecard(is_complete) for a group_scorecard_id
export function useGroupScorecardIsShowTotals(group_scorecard_id) {
  return useQuery(
    ["groupscorecardisshowtotalskey",group_scorecard_id],
    () =>
      supabase
        .from("group_scorecard")
        .select(`is_show_totals`)
        .eq("id", group_scorecard_id)
        .then(handle)
  );
}





//hook to get all group_scorecard_players on a group_scorecard
export function useGroupScorecardPlayers(group_scorecard_id) {
  // const queryResult = 
   return useQuery(
    ["groupscorecardplayerskey",group_scorecard_id],
    () =>
      supabase
        .from("group_scorecard_players")
        .select(`
          id,
          event_entries_id,
          group_scorecard(is_complete,is_show_totals),
          event_entries(
            is_dnf,
            hole_scores(
              hole_scores_id,
              score,
              layout_holes_id,
              layout_holes(
                hole_order,
                hole_id,
                holes(hole_name,hole_length,hole_description,hole_par)
              )
            ),
            users_public(name),
            user_id,
            event_divisions(layout_id),
            event_id
          )
          `)
        .eq("group_scorecard_id", group_scorecard_id)
        .then(handle)
  );
}

export function useGroupScorecardPlayer(event_entries_id) {
  return useQuery(
    ["groupscorecardplayerkey",event_entries_id],
    () =>
      supabase
    .from("hole_scores")
    .select(`
        score,
        layout_holes(hole_order)
        )
      `)
    .eq("event_entries_id", event_entries_id)
    .then(handle)
  );
}


//hook to get all group scorecards for an event
export function useGroupScorecards(event_id) {
  // console.log('using group scorecards', event_id)
  return useQuery(
    ["usegroupscorecardskey",event_id],
    () =>
      supabase
        .from("group_scorecard")
        .select(`id,created_at,completed_at,event_id,is_complete,group_scorecard_players(id,event_entries(id,user_id,users_public(name),event_divisions_id,event_divisions(divisions(name)),is_dnf,is_dropped,score))`)
        .eq("event_id", event_id)
        // .order('completed_at', {ascending: true})
        .order("created_at", { ascending: true })
        .then(handle)
  );
}

//get number of group_scorecard.id for an event_id WHERE is_complete = false HOOK
export function useGroupScorecardsNotComplete(event_id) {
  return useQuery(
    ["groupscorecardcountkey",event_id],
    () =>
      supabase
        .from("group_scorecard")
        .select(`id,group_scorecard_players(id,event_entries(id,user_id,users_public(name)))`)
        .eq("event_id", event_id)
        .eq("is_complete", false)
        .then(handle)
  );
}
  
//hook to check if a user is signed up for an event already
export function useEventEntriesByEventUser(event_id,user_id) {
  return useQuery(
    ["evententrieskey",event_id,user_id],
    () =>
      supabase
        .from("event_entries")
        .select(`id`)
        .eq("event_id", event_id)
        .eq("user_id", user_id)
        .then(handle)
  );
}

//get all 5s fora  player
export function getFives(user_id) {
  return supabase
    .from("hole_scores")
    .select("score, event_entries!event_entries_id(user_id)")
    .eq("score", 5)
    .eq("event_entries.user_id", user_id)
    .then(handle);
}


//hook for # of 5s for a player
export function useFives(user_id) {
  return useQuery(
    ["fiveskey", user_id],
    () =>
      supabase
        .from("hole_scores")
        .select("score, event_entries!event_entries_id(user_id)")
        .eq("score", 5)
        .eq("event_entries.user_id", user_id)
        .then(handle)
  );
}



export function useGroupScorecardsByEventUser(event_id,user_id) {
  return useQuery(
    ["groupscorecardskey",event_id,user_id],
    () =>
      supabase
        .from("group_scorecard_players")
        // .select(`group_scorecard_id,event_entries(event_id,user_id)`)
        .select(`group_scorecard_id,event_entries!inner(event_id,user_id),group_scorecard!inner(is_complete)`)
        .eq("event_entries.user_id", user_id)
        .eq("event_entries.event_id", event_id)
        .then(handle)
  );
}

//id, created_at, charge, amount_total, customer, payment_intent, currency, payment_status, amount_refunded

export function usePayments() {
  return useQuery(
    ["paymentskey"],
    () =>
      supabase
        .from("payments")
        .select("id,created_at,charge,amount_total,customer,payment_intent,currency,payment_status,amount_refunded,event_entries(event_id,events(name),user_id,users_public(name))")
        .order("created_at", { ascending: false })
        .then(handle)
  );
}

//function to update payments table refunds column
export function updatePaymentRefund(payment_id, refund_amount) {

  // after updating payment, reset the query with key "paymentskey"
  return supabase
    .from("payments")
    .update({ amount_refunded: refund_amount }) 
    .eq("payment_intent", payment_id)
    .then(handle)
    .then(() => client.invalidateQueries(["paymentskey"]));
}

// export function usePayments() {
//   return useQuery(
//     ["paymentskey"],
//     () =>
//       supabase
//         .from("payments")
//         .select("id,created_at,charge,amount_total,customer,payment_intent,currency,payment_status,amount_refunded")
//         .then(handle)
//   );
// }

// ,event_entries(id,event_id,user_id,users_public(name))





//this is not querying by iscomplete false
// export function useGroupScorecardsByEventUserIncomplete(event_id,user_id) {
//   return useQuery(
//     ["groupscorecardskey",event_id,user_id],
//     () =>
//       supabase
//         .from("group_scorecard_players")
//         // .select(`group_scorecard_id,event_entries(event_id,user_id)`)
//         .select(`group_scorecard_id,event_entries!inner(event_id,user_id),group_scorecard!inner(is_complete)`)
//         .eq("event_entries.user_id", user_id)
//         .eq("event_entries.event_id", event_id)
//         .eq("group_scorecard.is_complete", false)
//         .then(handle)
//   );
// }




//hook to get total number of event_entires_id for an event_id and user_id
// export function useEventEntriesCount(event_id,user_id) {
//   return useQuery(
//     ["evententriescountkey",event_id,user_id],
//     () =>
//       supabase
//         .from("event_entries")
//         .select(`id`)
//         .eq("event_id", event_id)
//         .eq("user_id", user_id)
//         .then(handle)
//   );
// }



//not working?
// export function useEventEntriesByEventUserScorecard(event_id, user_id) {
//   console.log(user_id, 'user_id')

//     return useQuery(
//       ["evententrieskeyscorecard", event_id, user_id],
//       () =>
//         supabase
//           .from("event_entries")
//           .select(`id`)
//           .eq("event_id", event_id)
//           .eq("user_id", user_id)
//           .then(handle)
//     );
// }
//list of entries for a player in a certain event
// export function useEntriesByEventPlayerScorecard(event_id,user_id){
//   return useQuery(
//         ["use_entries_player_event_scorecard",event_id, user_id],
//         () =>
//         supabase
//           .from("event_entries")
//           .select(`id,score,group_scorecard_players(group_scorecard_id,group_scorecard(is_complete))`)
//           .eq("event_id", event_id)
//           .eq("user_id", user_id)
//           .order(`id`, {ascending: false})
//           .then(handle),
//     )
// }



export function useScoreboard(eventId) {
  return useQuery(
    ["scoreboardkey",eventId],
    () =>
      supabase
        .from("hole_scores")
        .select(`hole_scores_id, score, layout_holes_id, layout_holes(hole_order, layout_id)`)
        .eq("event_id", eventId)
        .order("layout_holes(hole_order)")
        .then(handle)
  );
}

export function useLayoutHoles(layoutId) {
  return useQuery(
    ["layoutholeskey"],
    () =>
      supabase
        .from("layout_holes")
        .select(`layout_holes_id, hole_order, hole_id`)
        .eq("layout_id", layoutId)
        // .order("layout_holes(hole_order)")
        .then(handle)
  );
}

//use layouts to select layout_id, layout_name, course_id, courses(course_name) from layout
export function useLayouts() {
  return useQuery(
    ["layoutskey"],
    () =>
      supabase
        .from("layout")
        .select(`layout_id, layout_name, course_id, courses(course_name)`)
        .order("courses(course_name),layout_name")
        .then(handle)
  );
}

//usecourses to select all courses. course_name and course_id
export function useCourses() {
  return useQuery(
    ["courseskey"],
    () =>
      supabase
        .from("courses")
        .select(`id,course_name`)
        .order("id")
        .then(handle)
  );
}

export function useCoursesAdmin() {
  return useQuery(
    ["coursesadminkey"],
    () =>
      supabase
        .from("courses")
        .select(`id,course_name,created_at,is_displayed,added_by`)
        .order("course_name")
        .then(handle)
  );

}




//select all group_scorecard_id for an event_entries id from group_scorecard_players
export function useGroupScorecardId(eventEntriesId) {
  return useQuery(
    ["groupscorecardidkey",eventEntriesId],
    () =>
      supabase
        .from('group_scorecard_players')
        .select('group_scorecard_id')
        .eq("event_entries_id", eventEntriesId)
        .single()
        .then(handle)
  );
}

//select everything from event entries where event_id = event_id and event_entries_id is not in group_scorecard_players
export function usePlayersNotPlayingYet(eventId) {
  return useQuery(
    ["playersnotplayingyetkey",eventId],
    () =>
      supabase
        .from('event_entries')
        .select('id,user_id,id,users_public(name),event_divisions(layout_id),group_scorecard_players(event_entries_id)')
        .is('group_scorecard_players(event_entries_id)', null)
        .eq("event_id", eventId)
        .then(handle)
  );
}




export function getEventName(eventId) {
  return supabase
    .from("events")
    .select(`name`)
    .eq("id", eventId)
    .single()
    .then(handle);
}


//fetch layout id from event divisions for an event_entry_id non-hook
// export function getLayoutIdFromEventEntries(eventEntriesId) {
//   return supabase
//     .from("event_divisions")
//     .select("layout_id")
//     .eq("event_entries_id", eventEntriesId)
//     .single()
//     .then(handle);
// }

//check to see if email exists
export function checkEmail(email) {
  return supabase
    .from("users")
    .select("email")
    .eq("email", email)
    .then(handle);
}



//fetch scorecard (non-hook)
export function getScorecard(eventEntryId) {
  return supabase
    .from("hole_scores")
    .select(`hole_scores_id, score, layout_holes_id, layout_holes(hole_order, layout_id)`)
    .eq("event_entries_id", eventEntryId)
    .order("layout_holes(hole_order)")
    .then(handle)  
}

//fetch scorecard (hook)
export function useScorecardHoles(eventEntryId) {
  return useQuery(
    ["scorecardkey",eventEntryId],
    () =>
      supabase
        .from("hole_scores")
        .select(`hole_scores_id, score, layout_holes_id, layout_holes(hole_order, layout_id)`)
        .eq("event_entries_id", eventEntryId)
        .order("layout_holes(hole_order)")
        .then(handle),
  )
}

//is evententryid on a scorecard? (non-hook)
export function getIsOnAGroupScorecard(eventEntryId) {
  return supabase
    .from("group_scorecard_players")
    .select(`group_scorecard_id`)
    .eq("event_entries_id", eventEntryId)
    .then(handle)  
}

//isevententryid on a scorecard? (hook)
export function useIsOnAGroupScorecard(eventEntryId) {
  
  // return useQuery(
  //   ["evententryidonscorecard",eventEntryId],
  //   () =>
  //     supabase
  //       .from("group_scorecard_players")
        // .select(`group_scorecard_id`)
        // .eq("event_entries_id", eventEntryId)
        // .then(handle)
  // );

  return useQuery(
    ["isonascorecardkey",eventEntryId],
    () =>
      // supabase
        // .from("group_scorecard_players")
        // .select('id')
        // .eq("event_entries_id", eventEntryId)
        // .then(handle)

        supabase
        .from("group_scorecard_players")
        .select('group_scorecard_id')
        .eq("event_entries_id", eventEntryId)
        .then(handle),
        );



}

// fetch layout holes (non-hook)
export function getLayoutHoles(layoutId) {
  return supabase
    .from("layout_holes")
    .select(`layout_holes_id, hole_order, hole_id`)
    .eq("layout_id", layoutId)
    .order("hole_order")
    .then(handle)
}

//fetch event_divisions_id from event entries using event_entries_id
export function getEventDivisionsIdFromEventEntries(eventEntriesId) {
  return supabase
    .from("event_entries")
    .select("event_divisions_id")
    .eq("id", eventEntriesId)
    .single()
    .then(handle);
}

//fetch layout_id from event_divisions using event_divisions_id
export function getLayoutIdFromEventDivisions(eventDivisionsId) {
  return supabase
    .from("event_divisions")
    .select("layout_id")
    .eq("event_divisions_id", eventDivisionsId)
    .single()
    .then(handle);
}

// fetch layout_holes_id using hole_order and layout_id
export function getLayoutHoleId(layoutId,holeOrder) {
  return supabase
    .from("layout_holes")
    .select(`layout_holes_id`)
    .eq("layout_id", layoutId)
    .eq("hole_order", holeOrder)
    .single()
    .then(handle)
}

//hook to get holescore using layout_holes_id and event_entries_id
export function useHoleScore(layout_holes_id,event_entries_id) {

  console.log('getting hole score', layout_holes_id,event_entries_id)
  return useQuery(
    ["holescorekey",layout_holes_id,event_entries_id],
    () =>
      supabase
        .from("hole_scores")
        .select(`score`)
        .eq("layout_holes_id", layout_holes_id)
        .eq("event_entries_id", event_entries_id)
        .then(handle)
  );
}

//fetch layout hole id for a certain layout and hole order (non-hook)
// export function getLayoutHoleId(layoutId,holeOrder) {
//   return supabase
//     .from("layout_holes")
//     .select(`layout_holes_id`)
//     .eq("layout_id", layoutId)
//     .eq("hole_order", holeOrder)
//     .single()
//     .then(handle)
// }

// fetch id, method_name from payout_methods. not working
export function usePayoutMethods() {
  return useQuery(
    ["payoutmethodskey"],
    () =>
      supabase
        .from(`payout_methods`)
        .select(`id,method_name`)
        .order("id", { ascending: true })
        .then(handle)
  );
}

//non hook to fetch id, method_name from payout_methods
export function getPayoutMethods() {
  return supabase
    .from("payout_methods")
    .select(`id`)
    .then(handle)
}


// Fetch user data (non-hook)
export function getPlayerIdByEntryId(event_entries_id){
// get player id
  return supabase
  .from("event_entries")
  .select(`user_id`)
  .eq("id", event_entries_id)
  .then(handle);
}

export function getPlayersEntriesByEventIdPlayerIdGolf(event_id,player_id,event_divisions_id){

  console.log('getting players rounds')
 
    const response = supabase
    .from("event_entries")
    .select(`id,score`)
    .eq("user_id", player_id)
    .eq("event_id", event_id)
    .eq("event_divisions_id", event_divisions_id)
    .not("score", "is", null)
    .order('score', { ascending: true })
    .then(handle);

    // .eq("is_dropped", `false`)
    // console.log("response=", response)

    return response
}

export function getPlayersEntriesByEventIdPlayerIdNotGolf(event_id,player_id,event_divisions_id){

  console.log('getting players rounds')
 
    const response = supabase
    .from("event_entries")
    .select(`id,score`)
    .eq("user_id", player_id)
    .eq("event_id", event_id)
    .eq("event_divisions_id", event_divisions_id)
    .not("score", "is", null)
    .order('score', { ascending: false })
    .then(handle);

    // .eq("is_dropped", `false`)
    // console.log("response=", response)

    return response
}

// Useful if you need to fetch data from outside of a component
export function getUser(uid) {
  return supabase
    .from("users")
    .select(`*, customers ( * )`)
    .eq("id", uid)
    .single()
    .then(handle);
}

//fetch score for event entrie id (non hook)
export function getScore(event_entries_id){
  return supabase
  .from("event_entries")
  .select(`score`)
  .eq("id", event_entries_id)
  .then(handle);
}

// fetch score for event entrie id (hook)
export function useScore(event_entries_id){

  console.log('getting score', event_entries_id)
  return useQuery(
    ["event_entries_id_usescore", event_entries_id],
    () =>
    supabase
      .from("event_entries")
      .select(`score`)
      .eq("id", event_entries_id)
      .then(handle),
  )
}

//updatescoringordergolf
export async function updateScoringOrderGolf(eventId, scoring_order_golf) {
  const response = await supabase

    .from("events")
    .update({ scoring_order_golf })
    .eq("id", eventId)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["events"]);
  return response;
}


// Update an existing user
export async function updateUser(uid, data) {
  const response = await supabase
    .from("users")
    .update(data)
    .eq("id", uid)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["user", { uid }]);
  return response;
}




//remove a group_scorecard
export async function removeGroupScorecard(group_scorecard_id,event_id) {

  // console.log('removing group scorecard', event_id)
  
    const response = await supabase
      .from("group_scorecard")
      .delete()
      .eq("id", group_scorecard_id)
      .then(handle);

    // Invalidate and refetch queries that could have old data
    await client.invalidateQueries(["group_scorecard"])
    await client.invalidateQueries([event_id, "use_players"])
    //not invalidating query for eventmanagecards
    await client.invalidateQueries(["usegroupscorecardskey",event_id])
    
    // .then(() => {
    //   console.log('query invalidated')
    // })

        //invalidate query for usegroupscorecards
    // ["usegroupscorecardskey",event_id],

    return response;
}

  
//remove a group_scorecard_player
export async function removeGroupScorecardPlayer(group_scorecard_player_id) {
  
  const response = await supabase
    .from("group_scorecard_players")
    .delete()
    .eq("id", group_scorecard_player_id)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["group_scorecard_players"]);
  return response;
}




/**** ITEMS ****/
/* Example query functions (modify to your needs) */

//fetch hole_hole_score_edits from hole_score_edit table where hole_score_id = hole_score_id
export function getHoleScoreEdits(hole_score_id) {
  return supabase
    .from("hole_score_edits")
    .select(`*`)
    .eq("hole_scores_id", hole_score_id)
    .then(handle);
}


// Fetch item data
export function useItem(id) {
  return useQuery(
    ["item", { id }],
    () => supabase.from("items").select().eq("id", id).single().then(handle),
    { enabled: !!id }
  );
}

// Fetch all items by owner
export function useItemsByOwner(owner) {
  return useQuery(
    ["items", { owner }],
    () =>
      supabase
        .from("items")
        .select()
        .eq("owner", owner)
        .order("createdAt", { ascending: false })
        .then(handle),
    { enabled: !!owner }
  );
}

//check to see if a uid is present in the td_applications table where approval_status_id = 1
export function useIsTdApplication(uid) {
  return useQuery(
    ["istdapplicationkey", uid],
    () =>
      supabase
        .from("td_applications")
        .select(`user_id,td_notes,approval_status_id`)
        .eq("user_id", uid)
        .eq("approval_status_id", 1)
        .then(handle)
  );
}

//check to see if there are any td_applications with column approval_status_id = 1
export function useIsTdApplicationPending() {
  return useQuery(
    ["istdapplicationpendingkey"],
    () =>
      supabase
        .from("td_applications")
        .select(`id,user_id,users_public(name),td_notes,admin_notes`)
        .eq("approval_status_id", 1)
        .then(handle)
  );
}


//async apply to become a TD. insert uid into td_applications table user_id column, and notes into td_applications notes column
export async function applyToBeTd(uid,notes) {
  const response = await supabase.from("td_applications").insert([{user_id: uid, td_notes: notes}]).then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["users"]);
  return response;
}


//async create a new event and return the id
export async function createEvent(data) {
  const response = await supabase.from("events").insert([data]).then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["events"]);
  return response;
}

//async add event divisions into event_divisions table. data is an array of event division objects. do not return reponse until all event_divisions have been added
export async function createEventDivisions(data) {
  const response = await supabase.from("event_divisions").insert(data).then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["event_divisions"]);
  return response;
}


//create a new event_entry
export async function createEventEntry(data) {
  const response = await supabase.from("event_entries").insert([data]).then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["event_entries"]);
  return response;
}

//create a group scorecard
export async function createGroupScorecard(data) {
  const response = await supabase.from("group_scorecard").insert([data]).single().then(handle);

  // Invalidate and refetch queries that could have old data
  //avaiable players needs to be updated
  await client.invalidateQueries(["group_scorecard_players"]);

  // await client.invalidateQueries(["hole_scores"]);
  return response;
}

//create group scorecard players (add players to group scorecard)
export async function createGroupScorecardPlayers(data) {
  const response = await supabase.from("group_scorecard_players").insert([data]).then(handle);
  // Invalidate and refetch queries that could have old data
  // await client.invalidateQueries(["group_scorecard_players"]);
  await client.invalidateQueries(["groupscorecardplayerskey",data.group_scorecard_id]);
  return response;
}



// Create a new item
export async function createItem(data) {
  const response = await supabase.from("items").insert([data]).then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["items"]);
  return response;
}

//create a username
export async function createUserName(data,uid) {
  const response = await supabase.from("users_public").insert([data]).then(handle);

  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["users_public"]);
  //here
  await client.invalidateQueries(["usenamekey", uid])
  return response;
}

//update user public name
export async function updateUserName(uid,data) {
  const response = await supabase.from("users_public").update({name: data}).eq("id",uid).then(handle);
  
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["users_public"]);
  await client.invalidateQueries(["usenamekey", uid])
  return response;
}

//create a user phone
export async function createUserPhone(id,data) {
  const response = await supabase.from("users").update([data]).eq("id",id).then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["users"]);
  await client.invalidateQueries(["usephonekey", id])
  return response;
}

// Create a new hole_score
export async function createHoleScore(data) {
  // console.log('creating hole score', data)
  const response = await supabase.from("hole_scores").insert([data]).then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["hole_scores"]);
  return response;
}

// Update an item
export async function updateItem(id, data) {
  const response = await supabase
    .from("items")
    .update(data)
    .eq("id", id)
    .then(handle);

  // Invalidate and refetch queries that could have old data
  await Promise.all([
    client.invalidateQueries(["item", { id }]),
    client.invalidateQueries(["items"]),
   ]);

  return response;
}

// Update a hole score
export async function updateHoleScore(hole_scores_id,event_entries_id, data) {
  // console.log('updating hole score', hole_scores_id,event_entries_id, data)

  const response = await supabase
    .from("hole_scores")
    .update(data)
    .eq("hole_scores_id", hole_scores_id)
    .then(handle);

  // Invalidate and refetch queries that could have old data
  
  await Promise.all([
    client.invalidateQueries(["eventscorecardskey", event_entries_id])
   ]);
  return response;
}

//insert a edited score into hole_score_edits table
export async function insertScoreEdit(data) {
  const response = await supabase
  .from("hole_score_edits")
  .insert([data])
  .then(handle);
  
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["hole_score_edits"]);

  return response;
}

export async function deleteHoleScores(event_entries_id) {


  const response = await supabase
    .from("hole_scores")
    .delete()
    .eq("event_entries_id", event_entries_id)
    .then(handle);

      // Invalidate and refetch queries that could have old data
      await Promise.all([
        // client.invalidateQueries(["item", { id }]),
        client.invalidateQueries(["eventscorecardskey"]),
      ]);

      return response;
}

export function deleteEventEntryId(id) {
  return supabase
    .from('event_entries')
    .delete()
    .eq('id', id)
    .then(handle);
}

export function deleteEvent(id) {
  return supabase
    .from('events')
    .delete()
    .eq('id', id)
    .then(handle);
}

export async function deleteEventEntry(event_entries_id,event_id,event_divisions_id) {

  const response = await supabase
    .from("event_entries")
    .delete()
    .eq("id", event_entries_id)
    .then(handle);
      // Invalidate and refetch queries that could have old data
      await ([
        // client.invalidateQueries(["item", { id }]),
        client.invalidateQueries(["eventscorecardskey"]),
        client.invalidateQueries([event_id, "use_players"]),
        
        client.invalidateQueries(["use_players_division",event_id,event_divisions_id])
      ]);

      return response;
}

export function useDivisionByEventEntry(event_entries_id) {
  return useQuery(
    ["evententriesdivisionskey",event_entries_id],
    () =>
      supabase
        .from("event_entries")
        .select(`event_divisions_id`)
        .eq("event_entries_id", event_entries_id)
        .then(handle)
  );
}

export function useHoleScoreEdits(hole_scores_id) {
  return useQuery(
    ["scoreeditskey",hole_scores_id],
    () =>
      supabase
        .from("hole_score_edits")
        .select('created_at, score, editor_id, users_public(name),id')
        .eq("hole_scores_id", hole_scores_id)
        //order by created_at desc
        .order('created_at', {ascending: false})
        .then(handle)
  );
}



//list of scores for a certain player, event, and division
export function useEntriesByEventDivision(event_entries_id,event_id,event_divisions_id){

  console.log('get event entries for this person, division, and event', event_entries_id)

  return useQuery(
    ["use_players_division_event",event_id, event_entries_id],
    () => 
    supabase
      .from("event_entries")
      .select(`user_id,users_public(name),id,score,event_divisions_id,event_divisions(divisions(name)),points,payouts(payout_amount),is_dnf`)
      .eq("event_id", event_id)
      .eq("event_divisions_id", event_divisions_id)
      .order('is_dnf')
      .order('score', { ascending: true })
      .then(handle),
  )
}

//list of scores for a certain player, event, and division
export function useEntriesByEventPlayer(event_id,user_id){

  // console.log('get event entries and scores for this person (', user_id, ')and event (', event_id, ')')

    return useQuery(
      ["use_entries_player_event",event_id, user_id],
      () => 
      supabase
        .from("event_entries")
        .select(`id,score,event_divisions_id,event_divisions(divisions(name)),is_dnf,score,events(name),group_scorecard_players(group_scorecard_id)`)
        .eq("event_id", event_id)
        .eq("user_id", user_id)
        .is("score", null)
        .order('id')
        .then(handle),
    )
}

//list of entries for a player in a certain event
export function useEntriesByEventPlayerScorecard(event_id,user_id){
  return useQuery(
        ["use_entries_player_event_scorecard",event_id, user_id],
        () =>
        supabase
          .from("event_entries")
          .select(`id,score,group_scorecard_players(group_scorecard_id,group_scorecard(is_complete))`)
          .eq("event_id", event_id)
          .eq("user_id", user_id)
          .order(`id`, {ascending: false})
          .then(handle),
    )
}

//list of all players for check in guest feature
export function usePlayers(){
  return useQuery(
    ["use_players"],
    () =>
    supabase
      .from("users_public")
      .select(`id,name`)
      .order(`name`, {ascending: true})
      .then(handle),
  )
}

//set a group_scorecard to is_show_totals=false
export async function updateGroupScorecardIsShowTotalsFalse(id) {
  const data = {is_show_totals: false}
  const response = await supabase
    .from("group_scorecard")
    .update(data)
    .eq("id", id)
    .then(handle);
  // Invalidate and refetch queries that could ave old data
  await client.invalidateQueries(["group_scorecard_players", { id }]);
  return response;
}

//set up a group_scorecard to is_show_totals=true
export async function updateGroupScorecardIsShowTotalsTrue(id) {
  const data = {is_show_totals: true}
  const response = await supabase
    .from("group_scorecard")
    .update(data)
    .eq("id", id)
    .then(handle);
  // Invalidate and refetch queries that could ave old data
  await client.invalidateQueries(["group_scorecard_players", { id }]);
  return response;
}

//set group scorecard is_submitted
export async function updateScorecardIsSubmitted(id,event_id,user_id) {

  // console.log('updating scorecard to submitted', id,event_id,user_id)

    // const data = {is_complete: true}
    //data is_complete = true and completed_at = now()
    const data = {is_complete: true, completed_at: new Date()}
  
    //update
    const response = await supabase
      .from("group_scorecard")
      .update(data)
      .eq("id", id)
  
    // Invalidate and refetch queries that could ave old data
    await Promise.all([
      client.invalidateQueries(["group_scorecard_players", { id }]),
      client.invalidateQueries(["group_scorecard_players"]),
      client.invalidateQueries(['use_players_division']),
      client.invalidateQueries(["use_entries_player_event_scorecard",event_id, user_id]),
      client.invalidateQueries(["usegroupscorecardskey",event_id])
    ]);
  
    return response;
  }

  //set group scorecard is_submitted
export async function updateScorecardNotSubmitted(id,event_id,user_id) {

  const data = {is_complete: false}

  //update
  const response = await supabase
    .from("group_scorecard")
    .update(data)
    .eq("id", id)

  // Invalidate and refetch queries that could ave old data
  await Promise.all([
    client.invalidateQueries(["group_scorecard_players", { id }]),
    client.invalidateQueries(["group_scorecard_players"]),
    client.invalidateQueries(['use_players_division']),
    client.invalidateQueries(["use_entries_player_event_scorecard",event_id, user_id]),
    client.invalidateQueries(["usegroupscorecardskey",event_id])
  ]);

  return response;
}

  

export function useEntriesByEventPlayerAlreadyScored(event_id,user_id){

  // console.log('get event entries and scores for this person (', user_id, ')and event (', event_id, ')')

    return useQuery(
      ["use_entries_player_event",event_id, user_id],
      () => 
      supabase
        .from("event_entries")
        .select(`id,score,event_divisions_id,event_divisions(divisions(name)),is_dnf,score,events(name)`)
        .eq("event_id", event_id)
        .eq("user_id", user_id)
        // .is("score", null)
        .order('id')
        .then(handle),
    )
}

//check to see if a players round is already scored non hook
export function getEntriesByEventPlayerAlreadyScored(event_entries_id){
  return supabase
  .from("event_entries")
  .select(`score`)
  .eq("id", event_entries_id)
  .not("score", "is", null)
  .then(handle);
}

//update approval_status_id in td_applications table to 2 (approved) for inputed user_id
export async function approveTdApplication(user_id) {
  const data = {approval_status_id: 2}
  const response = await supabase
    .from("td_applications")
    .update(data)
    .eq("user_id", user_id)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["td_applications"]);
  await client.invalidateQueries(["istdapplicationpendingkey"]);
  await client.invalidateQueries(["istdapplicationkey", user_id]);
  return response;
}

//update user.is_td to true for inputed user_id
export async function updateUserIsTd(user_id) {
  const data = {is_td: true}
  const response = await supabase
    .from("users")
    .update(data)
    .eq("id", user_id)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["users"]);
  await client.invalidateQueries(["istdapplicationpendingkey"]);
  await client.invalidateQueries(["istdapplicationkey", user_id]);
  return response;
}

//update approval_status_id in td_applications table to 3 (denied) for inputed user_id
export async function denyTdApplication(user_id) {
  const data = {approval_status_id: 3}
  const response = await supabase
    .from("td_applications")
    .update(data)
    .eq("user_id", user_id)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["td_applications"]);
  return response;
}

//set event as complete (display payouts)
export async function updateEventCompleteTrue(id) {
  
  const data = {is_event_complete: true}

  //update
  const response = await supabase
    .from("events")
    .update(data)
    .eq("id", id)

  // Invalidate and refetch queries that could ave old data
  await Promise.all([
    // client.invalidateQueries(["event_entries", { id }]),
    // client.invalidateQueries(["event_entries"]),
    // client.invalidateQueries(['use_players_division'])
    client.invalidateQueries(["eventIdname",  id ])
  ]);

  return response;
}

//set event as not complete (display payouts)
export async function updateEventCompleteFalse(id) {
  
  const data = {is_event_complete: false}

  //update score
  const response = await supabase
    .from("events")
    .update(data)
    .eq("id", id)

  // Invalidate and refetch queries that could have old data
  await Promise.all([
  //   client.invalidateQueries(["event_entries", { id }]),
  //   client.invalidateQueries(["event_entries"]),
  //   client.invalidateQueries(['use_players_division'])
  client.invalidateQueries(["eventIdname",  id ])
  ]);

  return response;
}

//set event as not complete (display payouts)
export async function updateEventCalculatePayouts(id) {
  
  // const data = {is_event_complete: false}

  //update score
  // const response = await supabase
  //   .from("events")
  //   .update(data)
  //   .eq("id", id)
  const response = {}
  console.log("todo calling db method to calculate payouts")

  // Invalidate and refetch queries that could have old data
  // await Promise.all([
  //   client.invalidateQueries(["event_entries", { id }]),
  //   client.invalidateQueries(["event_entries"]),
  //   client.invalidateQueries(['use_players_division'])
  // client.invalidateQueries(["eventIdname",  id ])
  // ]);

  return response;
}

// Update a division
export async function updateDivision(eventEntryId, eventDivisionsId,eventId) {
  // console.log('updating score', id, data, eventId)

  const data = {event_divisions_id: eventDivisionsId}
  
  //update 
  const response = await supabase
    .from("event_entries")
    .update(data)
    .eq("id", eventEntryId)

  // Invalidate and refetch queries that could have old data
    await Promise.all([
    // client.invalidateQueries(["event_entries", { id }]),
    // client.invalidateQueries(["event_entries"]),
    client.invalidateQueries(["use_players"]),
    client.invalidateQueries(['use_players_division']),
    client.invalidateQueries(['use_players_division_event']),
    client.invalidateQueries([eventId, "use_players"])
  ]);

  return response;
}

// Update a score
export async function updateScore(id, data, eventId) {
  // console.log('updating score', id, data, eventId)
  
  //update score
  const response = await supabase
    .from("event_entries")
    .update(data)
    .eq("id", id)

  // Invalidate and refetch queries that could have old data
    await Promise.all([
    // client.invalidateQueries(["event_entries", { id }]),
    // client.invalidateQueries(["event_entries"]),
    client.invalidateQueries(["use_players"]),
    client.invalidateQueries(['use_players_division']),
    client.invalidateQueries(['use_players_division_event']),
    client.invalidateQueries([eventId, "use_players"])
  ]);

  return response;
}

// Update a score
export async function updateDropRound(id, data) {
  
  //update to dropped
  const response = await supabase
    .from("event_entries")
    .update(data)
    .eq("id", id)

  // Invalidate and refetch queries that could have old data
  await Promise.all([
    client.invalidateQueries(["event_entries", { id }]),
    client.invalidateQueries(["event_entries"]),
    client.invalidateQueries(['use_players_division'])
  ]);

  return response;
}

//drop a players worst rounds for an event
export async function updateDroppedRounds(player_id, event_id, event_divisions_id, scoringOrderGolf) {

  var data

  //get players scores from this event   
  (async () => {
    var response
    scoringOrderGolf
       ? response = await getPlayersEntriesByEventIdPlayerIdGolf(event_id,player_id,event_divisions_id) 
       : response = await getPlayersEntriesByEventIdPlayerIdNotGolf(event_id,player_id,event_divisions_id)
    
    
    console.log('Do something with response ', response);
    // console.log('response type', typeof(response));
    // console.log(response.length, " = response length")

    // loop through response
    data = response
    // var loopData = ''
    
    // console.log(data)
    // console.log(data[0])
    // console.log(data.length)

    var i ;
    var j;

    //if only one score, undrop it. Else, start at the 2nd score
    if(data.length === 1){
      j=0
    }else{
      j=1
    }
    console.log("looping through rounds to drop. length of array = ", data.length)

    //start loop at 1 so that you skip the round with the "best" score. Drop everything except 1
    for(i=j; i < data.length; i++){
      // loopData += `<li>${data[i].score}</li>`
      // console.log(data.id)
          updateDropRound(data[i].id, {is_dropped: true});
          console.log("dropping round", data[i].id)
    }
    
  })();



  // if(playersScores != null){
  // console.log(playersScores, " = playersScores")
  // }
  //get players scores from this event 
  // const result = getPlayersEntriesByEventIdPlayerId(event_id,player_id)
  
  // console.log(result)

  console.log("updating dropped rounds")
  
  // const response = await supabase
  //   .from("event_entries")
  //   .update(data)
  //   .eq("id", id)

  // Invalidate and refetch queries that could have old data
  await Promise.all([
    client.invalidateQueries(["event_entries"]),
    // client.invalidateQueries(["event_entries", { event_entries_id }]),
    client.invalidateQueries(['use_players_division']) 
  ]);

  return 1;
}

// set isdropped to false
export async function updateIsDroppedFalse(id) {
  const response = await supabase
    .from("event_entries")
    .update({is_dropped: false})
    .eq("id", id)

  // Invalidate and refetch queries that could have old data
  await Promise.all([
    client.invalidateQueries(["event_entries", { id }]),
    client.invalidateQueries(["event_entries"]),
    client.invalidateQueries(['use_players_division'])
  ]);
  return response;
}

//updateeventname
export async function updateEventName(id, data) {
  console.log('updating event name', id, data)
  const response = await supabase
    .from("events")
    .update({name: data})
    .eq("id", id)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["events"]);
  return response;
}

// set isdropped to true
export async function updateIsDroppedTrue(id) {
  const response = await supabase
    .from("event_entries")
    .update({is_dropped: true})
    .eq("id", id)

  // Invalidate and refetch queries that could have old data
  await Promise.all([
    client.invalidateQueries(["event_entries", { id }]),
    client.invalidateQueries(["event_entries"]),
    client.invalidateQueries(['use_players_division'])
  ]);
  return response;
}

// set points to null
export async function updatePointsNull(id) {
  const response = await supabase
    .from("event_entries")
    .update({points: null})
    .eq("id", id)

  // Invalidate and refetch queries that could have old data
  await Promise.all([
    client.invalidateQueries(["event_entries", { id }]),
    client.invalidateQueries(["event_entries"]),
    client.invalidateQueries(['use_players_division'])
  ]);
  return response;
}

// set playoff winner to null
export async function updatePlayoffWinnerNull(id) {
  const response = await supabase
    .from("event_entries")
    .update({is_playoff_winner: null})
    .eq("id", id)

  // Invalidate and refetch queries that could have old data
  await Promise.all([
    client.invalidateQueries(["event_entries", { id }]),
    client.invalidateQueries(["event_entries"]),
    client.invalidateQueries(['use_players_division'])
  ]);
  return response;
}

// set player as dnf
export async function updateDnf(id, data, event_id) {
  const response = await supabase
    .from("event_entries")
    .update(data)
    .eq("id", id)

  // Invalidate and refetch queries that could have old data
  await Promise.all([
    client.invalidateQueries(["event_entries", { id }]),
    client.invalidateQueries(["event_entries"]),
    client.invalidateQueries(['use_players_division']),
    client.invalidateQueries([event_id, "use_players"])
  ]);

  return response;
}

// Delete an item
export async function deleteItem(id) {
  const response = await supabase
    .from("items")
    .delete()
    .eq("id", id)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await Promise.all([
    client.invalidateQueries(["item", { id }]),
    client.invalidateQueries(["items"]),
  ]);
  return response;
}

/**** HELPERS ****/

// Get response data or throw error if there is one
function handle(response) {
  if (response.error) throw response.error;
  return response.data;
}

// React Query context provider that wraps our app
export function QueryClientProvider(props) {
  return (
    <QueryClientProviderBase client={client}>
      {props.children}
    </QueryClientProviderBase>
  );
}